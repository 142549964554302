import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllProjects } from "./project.thunk";
import { toast } from "react-toastify";

const INIT_STATE = [];

export const fetchAllProjectsAsyncThunk = createAsyncThunk(
    "fetchProjects",
    fetchAllProjects
)


const ProjectSlice = createSlice({
    name:"project",
    initialState:INIT_STATE,
    reducers:{

    },
    extraReducers:(builders)=>{
        builders
        .addCase(fetchAllProjectsAsyncThunk.pending,(state)=>{
            return state;
        })
        .addCase(fetchAllProjectsAsyncThunk.rejected,(state)=>{
            toast.error("Fetching projects succesfully");
            return state;
        })
        .addCase(fetchAllProjectsAsyncThunk.fulfilled,(state,action)=>{
            return action.payload.data;
        })
    }
})


export default ProjectSlice.reducer;