import { BsSearch, BsXCircle } from "react-icons/bs";
import Input from "../Input";
import styles from "./index.module.css";
import MultiSelectDropDown from "../MultiSelectDropdown";
import NameDisplay from "../NameDisplay";
import H2 from "../../typography/H2";
import SubmitBtn from "../SubmitBtn";
import H5 from "../../typography/H5";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjectsAsyncThunk } from "../../../store/slices/project/project.slice";
import { deleteProjectById, fetchUniqueCities } from "../../../utils/calls.utils";
import {  useNavigate } from "react-router-dom";
import Modal from "../Modal";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import {toast} from "react-toastify";


const MobileCard = ({project,handleDelete,user})=>{

    const navigate = useNavigate();

    const handleUpdate = (e)=>{
        localStorage.setItem("is_project_add",false);
        localStorage.setItem("project_",JSON.stringify(project));

        navigate("/projects");
    }
    const onDealView = ()=>{
        navigate(`/dashboard/deals?projectId=${project?._id}`);
    }
    return <div className={styles["card"]}>
        <div>
            {user?.role=="admin" &&<div onClick={()=>handleDelete(project)} className={styles["cta"]}><BsXCircle/></div>}
        </div>
        <div>
            <div>
                <H5>Project Name</H5>
                <div>{project?.name}</div>
            </div>
            <div>
                <H5>City</H5>
                <div>{project?.city}</div>
            </div>
        </div>
        <div>
            <div>
                <H5>No Of Deals</H5>
                <div>{project?.dealCount}</div>
            </div>
            <div>
                <H5></H5>
                <div></div>
            </div>
        </div>
        <div>
            <div>
                <div className={styles["cta"]} onClick={onDealView}>View Deals</div>
            </div>
            <div>
                <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
            </div>
        </div>
    </div>;
}

const DesktopCard = ({project,handleDelete,user})=>{
    const navigate = useNavigate();

    const handleUpdate = (e)=>{
        localStorage.setItem("is_project_add",false);
        localStorage.setItem("project_",JSON.stringify(project));

        navigate("/projects");
    }

    const onDealView = ()=>{
        navigate(`/dashboard/deals?projectId=${project?._id}`);
    }
    return <div className={styles["row"]}>
        <div>{project?.name}</div>
        <div>{project?.city}</div>
        <div>{project?.dealCount}</div>
        <div className={styles["cta"]} onClick={onDealView}>View Deals</div>
        <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
        {user?.role=="admin" &&<div className={styles["cta"]} onClick={()=>handleDelete(project)}><BsXCircle/></div>}
    </div>;
}
const Projects = (props)=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projects = useSelector(state=>state.project);
    const [projectName,setProjectName] = useState("");
    const [uniqueCities,setUniqueCities] = useState([]);
    const [citiesSelected,setCitiesSelected] = useState({});
    const [modal,setModal] = useState({show:false,data:null});
    const [load,setLoad] = useState(false);
    const [user,setUser] = useState({});
//c
    const onProjectNameChange = (e)=>{
        setProjectName(e.target.value);
    }

    const onSelectedCityChange = (obj)=>{
        setCitiesSelected(obj);
    }

    const onAddProjectHandler = (e)=>{
        localStorage.setItem("is_project_add",true);
        navigate("/projects");
    }

    const handleDelete = (project)=>{
        setModal({show:true,data:project});
    }

    useEffect(()=>{
        let city = "";
        for(let cities in citiesSelected){
            if(citiesSelected[cities]) city+=`${cities},`;
        }
        dispatch(fetchAllProjectsAsyncThunk({name:projectName,city}));
    },[projectName,citiesSelected,load]);

    useEffect(()=>{
        fetchUniqueCities()
        .then(res=>{
            setUniqueCities(res?.data);
        })
        .catch(err=>{
            console.log(err);
            toast.error("Fetching unique cities failed");
        })

        if(localStorage.getItem("user_")) setUser(JSON.parse(localStorage.getItem("user_")));
    },[])

    const onAction =async (e)=>{
        try{
            dispatch(showLoader(`Deleting ${modal?.data?.name}, Please wait..`));
            await deleteProjectById(modal?.data?._id);
            toast.success("Project deleted succesfully");
        }catch(err){
            console.log(err);
            toast.error("Deleting project failed");
        }finally{
            setLoad(state=>!state);
            dispatch(hideLoader());
            setModal({show:false,data:null});
        }
    }

    const onClose =  (e)=>{
        setModal({show:false,data:null});
    }

    return <>
    {modal.show && <Modal 
    onAction={onAction}
    onClose={onClose}
    action={"delete"}
    message={`Are you sure you want to delete ${modal?.data?.name}`}
    />}
    <div className={styles["container"]}>
        <div className={styles["filters"]}>
            <div className={styles["input"]}>
                <Input value={projectName} type="text" placeholder="Search for project name" onChange={onProjectNameChange}/>
                {/* <div className={styles["search"]}><BsSearch/></div> */}
            </div>
            <div className={styles["btns"]}>
                <div>
                    <MultiSelectDropDown title={"City"} options={uniqueCities} onChange={onSelectedCityChange}/>
                </div>
                <div>
                    <NameDisplay name={`${user?.firstName} ${user?.lastName}`}/>
                </div>
            </div>
        </div>
        <div className={styles["data"]}>
            <div className={styles["head"]}>
                <H2>Projects</H2>
                <SubmitBtn text="ADD PROJECT" onClick={onAddProjectHandler}/>
            </div>
            {projects?.length <= 0 && <div style={{width:"100%", textAlign:"center"}}><H5>No Projects Found, Create Your First Project Using Add Project Button</H5></div>}
            {projects?.length > 0 && <div className={styles["content"]}>
                {window.innerWidth<= 700 && (<>
                    {projects.map(project=>{
                        return <MobileCard user={user} project={project} handleDelete={handleDelete}/>
                    })}
                </>)}
                {window.innerWidth > 700 && (<><div className={styles["top"]}>
                    <div><H5>Project Name</H5></div>
                    <div><H5>City</H5></div>
                    <div><H5>No Of Deals</H5></div>
                </div>
                {
                    projects.map(project=>{
                        return <DesktopCard user={user} project={project} handleDelete={handleDelete}/>
                    })
                }
                </>)}
            </div>}
        </div>
    </div></>
}

export default Projects;