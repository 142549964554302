import { useNavigate } from "react-router-dom";
import Form from "../../elements/Form1";
import Input from "../../elements/Input";
import Label from "../../elements/Label";
import SubmitBtn from "../../elements/SubmitBtn";
import H1 from "../../typography/H1";
import H2 from "../../typography/H2";
import H5 from "../../typography/H5";
import HR from "../../typography/HR";
import P from "../../typography/P";
import styles from "./index.module.css";
import { isEqualTo, isLengthGreaterThan } from "../../../utils/validators";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ProjectForm = (props)=>{
    const navigate = useNavigate();

    const [project,setProject]=useState({
        name:{
            value:"",
            error:""
        },
        address:{
            value:"",
            error:""
        },
        city:{
            value:"",
            error:""
        },
        postalCode:{
            value:"",
            error:""
        },
    });
    
    useEffect(()=>{
        const project = JSON.parse(localStorage.getItem('project'));
        if(project)
        setProject(project);
        else if(localStorage.getItem('is_project_add')!=="true")
        {
            let project = JSON.parse(localStorage.getItem("project_"));

            if(!project) return;

            project = {
                name:{value:project?.name,error:""},
                address:{value:project?.addressLine,error:""},
                city:{value:project?.city,error:""},
                postalCode:{value:project?.postalCode,error:""},
            }

            setProject(project);
        }
    },[]);

    console.log(project);

    const onChange = (e)=>{
        setProject(state=>{
            let newState = JSON.parse(JSON.stringify(state));

            //validations
            if(e?.target?.name=="name"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Project name is required!";
            }
            else if(e?.target?.name=="name"){
                newState[e?.target?.name].error="";
            }

            if(e?.target?.name=="address"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Project address is required!";
            }
            else if(e?.target?.name=="address"){
                newState[e?.target?.name].error="";
            }

            if(e?.target?.name=="city"&&!isLengthGreaterThan(e?.target?.value, 0)){
                newState[e?.target?.name].error="Project city is required!";
            }
            else if(e?.target?.name=="city"){
                newState[e?.target?.name].error="";
            }
            
            if(e?.target?.name=="postalCode"&&!isEqualTo(e?.target?.value, 6)){
                newState[e?.target?.name].error="Postal code should be 6 digits!";
            }
            else if(e?.target?.name=="postalCode"){
                newState[e?.target?.name].error="";
            }

            newState[e?.target?.name].value = e?.target?.value;

            return newState;
        })
    }
    
    const nextSubmissionHandler = ()=>{
        if(project?.name?.error!=""||project?.address?.error!=""||project?.city?.error!=""||project?.postalCode?.error!=""||project?.name?.value==""||project?.address?.value==""||project?.city?.value==""||project?.postalCode?.value==""){
            return toast.error("Submission invalid!");
        }
        
        localStorage.setItem("project",JSON.stringify(project));
        
        navigate("/builders");
    }

    const onDiscard = ()=>{
        localStorage.removeItem("builder");
        localStorage.removeItem("project");

        navigate("/dashboard/projects");
    }

    return <div className={styles["container"]}>
            <Form>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>{localStorage.getItem('is_project_add')==="true"?"Add":"Update"} Project</H1>
                    <P style={{borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}>Step 1 of 2, Fill all info correctly to goto step 2</P>
                    <div className={styles["input"]}>
                        <Label>Project Name</Label>
                        <Input type="text" placeholder="Seton Serenity"  value={project?.name?.value} error={project?.name?.error} onChange={onChange} name="name"/>
                    </div>
                    <div className={styles["input"]}>
                        <Label>Address</Label>
                        <Input type="text" placeholder="Main Street, Southeast" value={project?.address?.value} error={project?.address?.error} onChange={onChange} name="address"/>
                    </div>
                    <div className={styles["split"]}>
                        <div className={styles["input"]}>
                            <Label>City</Label>
                            <Input type="text" placeholder="Calgary" value={project?.city?.value} error={project?.city?.error} onChange={onChange} name="city"/>
                        </div>
                        <div className={styles["input"]}>
                            <Label>Postal Code</Label>
                            <Input type="text" placeholder="20577" value={project?.postalCode?.value} error={project?.postalCode?.error} onChange={onChange} name="postalCode"/>
                        </div>
                    </div>
                    <div className={styles["cta"]}>
                        <SubmitBtn text="Next" onClick={nextSubmissionHandler}/>
                        <H5 style={{color:"var(--color_dark_1)",cursor:"pointer"}} onClick={onDiscard}>Discard</H5>
                    </div>
                </div>
            </Form>
    </div>
}

export default ProjectForm;