import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginHandler } from "./auth.thunk";
import { removeAccessToken, storeAccessToken } from "../../../utils/global/auth.global";
const INIT_STATE = {
    isLoggedIn:false
}

export const loginAsyncThunk = createAsyncThunk(
    "login",
    loginHandler
)

const authSlice = createSlice({
    name:"Auth",
    initialState:INIT_STATE,
    reducers:{
        setAuthState:(state,action)=>{
            state.auth = action.payload;
        },
        logout:(state)=>{
            removeAccessToken();
            return {isLoggedIn:false};
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(loginAsyncThunk.pending,(state)=>{
            return state;
        })
        .addCase(loginAsyncThunk.fulfilled,(state,payload)=>{
            storeAccessToken(payload?.payload?.jwt);
            return {isLoggedIn:true};
        })
        .addCase(loginAsyncThunk.rejected,(state)=>{
            return state;
        })
    }

})

export const {setAuthState,logout} = authSlice.actions;

export default authSlice.reducer;