import { defaultAxios } from "./axios/default.axios";
import { userAxios } from "./axios/user.axios"

export const fetchUniqueCities = async ()=>{
    return await userAxios.get("/project/unique-cities");
}

export const fetchUniqueCompanies = async ()=>{
    return await userAxios.get("/agent/unique-companies");
}

export const fetchDealById = async (id)=>{
    return await userAxios.get("/deal/"+id);
}

export const fetchAllDeals = async ()=>{
    return await userAxios.get("/deal");
}

export const fetchAgentById = async (id)=>{
    return await userAxios.get("/agent/"+id);
}
export const fetchDealDocumentById = async (id)=>{
    return await userAxios.get("/deal/document/deal?dealId="+id);
}
export const postExpense = async (data)=>{
    
    const postData = new FormData();

    postData.append("label",data?.label?.value);
    postData.append("amount",data?.amount?.value);
    postData.append("date",data?.date?.value);
    if(data?.deal?.name)
    postData.append("deal",data?.deal?.name);
    if(data?.from?.name)
    postData.append("from",data?.from?.name);

    if(data?.attachment) postData?.append("attachment",data?.attachment);

    return await userAxios.post("/expense",postData);
}
export const postIncome = async (data)=>{
    
    const postData = new FormData();

    postData.append("label",data?.label?.value);
    postData.append("amount",data?.amount?.value);
    postData.append("date",data?.date?.value);
    if(data?.deal?.name)
    postData.append("deal",data?.deal?.name);

    if(data?.attachment) postData?.append("attachment",data?.attachment);

    return await userAxios.post("/income",postData);
}
export const getMyProfile = async ()=>{
    return await userAxios.get("/user");
}
export const updateExpense = async (data,id)=>{
    const postData = new FormData();

    postData.append("label",data?.label?.value);
    postData.append("amount",data?.amount?.value);
    postData.append("date",data?.date?.value);
    if(data?.deal?.name)
    postData.append("deal",data?.deal?.name);
    if(data?.from?.name)
    postData.append("from",data?.from?.name);

    if(data?.attachment) postData?.append("attachment",data?.attachment);

    return await userAxios.patch("/expense/"+id,postData);
}
export const updateIncome = async (data,id)=>{
    const postData = new FormData();

    postData.append("label",data?.label?.value);
    postData.append("amount",data?.amount?.value);
    postData.append("date",data?.date?.value);
    if(data?.deal?.name)
    postData.append("deal",data?.deal?.name);
    
    if(data?.attachment) postData?.append("attachment",data?.attachment);

    return await userAxios.patch("/income/"+id,postData);
}
export const deleteExpense = async (id)=>{
    return await userAxios.delete("/expense/"+id);
}
export const deleteIncome = async (id)=>{
    return await userAxios.delete("/income/"+id);
}
export const getDownloadableUrl = async (url)=>{
    return await userAxios.get("/deal/document?url="+url);
}
export const postProject = async (builder,project)=>{
    const postData = {
        projectName:project?.name?.value,
        addressLine:project?.address?.value,
        city:project?.city?.value,
        postalCode:project?.postalCode?.value,
        builderName:builder?.name?.value,
        builderAddressLine:builder?.address?.value,
        builderCity:builder?.city?.value,
        builderPostalCode:builder?.postalCode?.value
    }

    return await userAxios.post("/project",postData);
}

export const postDeposit = async (deposit,dealId)=>{
    const postData = new FormData();

    if(deposit?.amount?.value)
    postData.append('amount',deposit?.amount?.value);

    if(typeof deposit?.proof !== "string" && deposit?.proof)
    postData.append('proof',deposit?.proof);

    if(deposit?.scheduled_date?.value)
    postData.append('scheduled_date',deposit?.scheduled_date?.value);

    return await userAxios.post("/deal/"+dealId+"/deposit",postData);
}

export const updateDeposit =  async (deposit,dealId)=>{
    const postData = new FormData();

    if(deposit?.amount?.value)
    postData.append('amount',deposit?.amount?.value);

    if(typeof deposit?.proof !== "string" && deposit?.proof)
    postData.append('proof',deposit?.proof);

    if(deposit?.scheduled_date?.value)
    postData.append('scheduled_date',deposit?.scheduled_date?.value);

    return await userAxios.patch("/deal/"+dealId+"/deposit/"+deposit?._id,postData);
}

export const postDeal = async (deal)=>{
    const postData = {
        project:deal?.project?.name,
        unit_no:deal?.unit?.value,
        job_number:deal?.job_number?.value,
        mortgage_notes:deal?.mortgage_notes?.value
    }

    return await userAxios.post("/deal",postData);
}

export const postPurchaserByDealId = async (dealId,email)=>{
    const postData = { email}
    return await userAxios.post("/deal/"+dealId+"/purchaser",postData);
}
export const sendEmailToPurchaserByIds = async (dealId,purchaserId)=>{
    return await userAxios.post("/deal/"+dealId+"/purchaser/"+purchaserId+"/mail");
}

export const deletePurchasersById = async (dealId,purchaserId)=>{
    return await userAxios.delete("/deal/"+dealId+"/purchaser/"+purchaserId);
}


export const postAgent = async (agent)=>{
    const postData = {
        name:agent?.name?.value,
        addressLine:agent?.address?.value,
        city:agent?.city?.value,
        postalCode:agent?.postalCode?.value,
        phone:agent?.phone?.value,
        company:agent?.company?.value,
    }

    return await userAxios.post("/agent",postData);
}

export const updateDeal = async (data, id)=>{
    return await userAxios.patch("/deal/"+id,data);
}

export const updatePurchaserByIds = async (dealId,purchaserId,data,isPurchaser=false)=>{
    const postData = new FormData();

    console.log(typeof data.driver_license != "string",data.driver_license);
    if(data?.email?.value) postData.append('email',data?.email?.value);
    if(data?.first_name?.value) postData.append('first_name',data?.first_name?.value);
    if(data?.last_name?.value) postData.append('last_name',data?.last_name?.value);
    if(data?.designation?.value) postData.append('designation',data?.designation?.value);
    if(data?.company?.value) postData.append('company',data?.company?.value);
    if(data?.address_line?.value) postData.append('address_line',data?.address_line?.value);
    if(data?.city?.value) postData.append('city',data?.city?.value);
    if(data?.pincode?.value) postData.append('pincode',data?.pincode?.value);
    if(data?.phone?.value) postData.append('phone',data?.phone?.value);
    if(data?.dob?.value) postData.append('dob',data?.dob?.value);
    if(data?.driver_license&&typeof data?.driver_license != "string")
    postData.append('driver_license',data?.driver_license);
    if(data?.pr_or_pass&&typeof data?.pr_or_pass != "string")
    postData.append('pr_or_pass',data?.pr_or_pass);
    if(data?.utility_bill&&typeof data?.utility_bill != "string")
    postData.append('utility_bill',data?.utility_bill);

    if(isPurchaser)
    return await defaultAxios.patch("/deal/"+dealId+"/purchaser/"+purchaserId+"?isPurchaser=true",postData);
    else
    return await userAxios.patch("/deal/"+dealId+"/purchaser/"+purchaserId+"?isPurchaser=false",postData)

}
export const updateAgent = async (agent,id)=>{
    const postData = {
        name:agent?.name?.value,
        addressLine:agent?.address?.value,
        city:agent?.city?.value,
        postalCode:agent?.postalCode?.value,
        phone:agent?.phone?.value,
        company:agent?.company?.value,
    }

    return await userAxios.patch("/agent/"+id,postData);
}

export const updateProject = async (builder,project,id)=>{
    const postData = {
        projectName:project?.name?.value,
        addressLine:project?.address?.value,
        city:project?.city?.value,
        postalCode:project?.postalCode?.value,
        builderName:builder?.name?.value,
        builderAddressLine:builder?.address?.value,
        builderCity:builder?.city?.value,
        builderPostalCode:builder?.postalCode?.value
    }

    return await userAxios.patch("/project/"+id,postData);
}

export const deleteProjectById = async (id)=>{
    return await userAxios.delete("/project/"+id);
}

export const deleteAgentById = async (id)=>{
    return await userAxios.delete("/agent/"+id);
}

export const deleteDealById = async (id)=>{
    return await userAxios.delete("/deal/"+id);
}
export const updateSequence = async (data)=>{
    return await userAxios.patch("/deal/sequence",data);
}

//temp
export const saveSequence = async ()=>{
    return await userAxios.post("/deal/sequence");
}