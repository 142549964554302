import { useDispatch, useSelector } from "react-redux";
import Form from "../../elements/Form1";
import Input from "../../elements/Input";
import Label from "../../elements/Label";
import SubmitBtn from "../../elements/SubmitBtn";
import H1 from "../../typography/H1";
import H2 from "../../typography/H2";
import H5 from "../../typography/H5";
import HR from "../../typography/HR";
import P from "../../typography/P";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { isEqualTo, isLengthGreaterThan } from "../../../utils/validators";
import { useEffect, useRef, useState } from "react";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import { postAgent, updateAgent, updateExpense } from "../../../utils/calls.utils";
import { postExpense } from "../../../utils/calls.utils";
import { toast } from "react-toastify";
import { BsDownload, BsLink45Deg } from "react-icons/bs";
import { redirectUsingFileName } from "../../../utils/download.utils";
import SingleSelectDropdownv2 from "../../elements/SingleSelectDropdownv2";

//
const ExpenseForm = (props)=>{
    const fileRef = useRef();
    const [attachment,setAttachment] = useState(null);
    let projects = useSelector(state=>state?.deal);
    projects = projects?.deals?.map(deal=>{return {name:deal?._id,value:deal?.job_number}});
    let agents = useSelector(state=>state.agent);
    agents = agents?.agents?.map(agent=>{return {name:agent?._id,value:agent?.name}});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [expense,setExpense]=useState({
        label:{
            value:"",
            error:""
        },
        amount:{
            value:"",
            error:""
        },
        date:{
            value:"",
            error:""
        },
        attachment:null,
        deal:null,
        from:null
    });

    useEffect(()=>{
    
        if(localStorage.getItem('is_expense_add')!=="true")
        {
            let expense = JSON.parse(localStorage.getItem("expense_"));

            if(!expense) return;

            let expenses = {
                label:{value:expense?.label,error:""},
                amount:{value:expense?.amount,error:""},
                date:{value:expense?.date?.split("T")[0],error:""},
                attachment:null,
                deal:{name:expense?.deal?._id,value:expense?.deal?.job_number},
                from:{name:expense?.from?._id,value:expense?.from?.name}
            }

            if(expense?.attachment) setAttachment(expense?.attachment);

            setExpense(expenses);
        }
    },[]);

    const onChange = (e,isFile)=>{

        setExpense(state=>{
            let newState = JSON.parse(JSON.stringify(state));

            //validations
            if(e?.target?.name=="label"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Expense label is required!";
            }
            else if(e?.target?.name=="label"){
                newState[e?.target?.name].error="";
            }

            if(e?.target?.name=="amount"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Expense amount is required!";
            }
            else if(e?.target?.name=="amount"&&(parseFloat(e?.target?.value)<0)){
                newState[e?.target?.name].error="Invalid Expense Amount!";
            }
            else if(e?.target?.name=="amount"){
                newState[e?.target?.name].error="";
            }

            if(isFile)
            newState[e?.target?.name] = e?.target?.files[0];
            else
            newState[e?.target?.name].value = e?.target?.value;

            return newState;
        })
    }

    const onDiscard = ()=>{
        navigate("/dashboard/expenses");
    }

    const onSubmit = async (e)=>{
        try{
            if(expense?.label?.error!=""||expense?.amount?.error!=""||expense?.date?.error!=""||expense?.label?.value==""||expense?.amount?.value==""||expense?.date?.value==""){
                return toast.error("Submission invalid!");
            }

            if(localStorage.getItem('is_expense_add')==="true")
                dispatch(showLoader("Creating new Expense, Please wait ..."));
            else
                dispatch(showLoader("Updating expense, Please wait ..."));

            let res;
            if(localStorage.getItem('is_expense_add')==="true")
                res = await postExpense(expense);
            else{
                let id = JSON.parse(localStorage.getItem('expense_'))?._id;

                res = await updateExpense(expense,id);
            }

            navigate("/dashboard/expenses");
        }catch(err){
            toast.error('Submission failed, try again!');
            console.log(err);
        }finally{
            dispatch(hideLoader());
        }
    }
    const onDownload =async ()=>{
        await redirectUsingFileName(attachment);
    }
    const onFileUploadClickHandler = ()=>fileRef?.current?.click();
    const onProjectChange = (e)=>{
        setExpense(state=>{
            const newState = JSON.parse(JSON.stringify(state));
            newState.deal = e;
            return newState;
        })
    }
    const onFromChange = (e)=>{
        setExpense(state=>{
            const newState = JSON.parse(JSON.stringify(state));
            newState.from = e;
            return newState;
        })
    }
    return <div className={styles["container"]}>
            <Form>
                <div className={styles["wrapper"]}>
                    <input name="attachment" style={{display:"none",visibility:"hidden"}} type="file" ref={fileRef} onChange={(e)=>onChange(e,true)}/>
                    <H1 style={{marginBottom:"1.1rem"}}>{localStorage.getItem('is_expense_add')==="true"?"Add":"Update"} Expense</H1>
                    <P style={{borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}></P>
                    {/* <HR/> */}
                    <div className={styles["input"]}>
                        <Label>Label</Label>
                        <Input type="text" placeholder="Rental" name="label" onChange={onChange} error={expense?.label?.error} value={expense?.label?.value} />
                    </div>
                    <div className={styles["input"]}>
                        <Label>Amount</Label>
                        <Input type="number" placeholder="20000" name="amount" onChange={onChange} error={expense?.amount?.error} value={expense?.amount?.value}/>
                    </div>
                    <div className={styles["input"]}>
                        <Label>Date</Label>
                        <Input type="date"  name="date" onChange={onChange} error={expense?.date?.error} value={expense?.date?.value}/>
                    </div>
                    <div className={styles["documentBox"]}>
                        <div>
                            <H5>Attachment</H5>
                        </div>
                        <div>
                            {attachment && <BsDownload onClick={onDownload}/>}
                       </div>
                        <div onClick={onFileUploadClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{expense?.attachment?.name}</div>
                    <div style={{marginBottom:"calc(1.5 * var(--basex))"}}>
                        <SingleSelectDropdownv2 selected={expense?.deal?expense?.deal:{value:""}} onChange={onProjectChange} title={"Select Deal"} options={projects} style={{justifyContent:"space-between"}}/>
                    </div>
                    <div style={{marginBottom:"calc(1.5 * var(--basex))"}}>
                        <SingleSelectDropdownv2 selected={expense?.from?expense?.from:{value:""}} onChange={onFromChange} title={"Select Agent"} options={agents} style={{justifyContent:"space-between"}}/>
                    </div>
                    <div className={styles["cta"]}>
                        <SubmitBtn text={`${localStorage.getItem('is_expense_add')==="true"?"Add":"Update"} Expense`} onClick={onSubmit}/>
                        <H5 style={{color:"var(--color_dark_1)",cursor:"pointer"}} onClick={onDiscard}>Discard</H5>
                    </div>
                </div>
            </Form>
    </div>
}

export default ExpenseForm;