import { useDispatch, useSelector } from "react-redux";
import P from "../../typography/P";
import Input from "../Input";
import Label from "../Label";
import SingleSelectDropdownv2 from "../SingleSelectDropdownv2";
import SubmitBtn from "../SubmitBtn";
import styles from "./index.module.css";

import React, { useEffect, useState } from 'react'
import { fetchAllProjectsAsyncThunk } from "../../../store/slices/project/project.slice";
import { isLengthGreaterThan } from "../../../utils/validators";
import { postDeal, updateDeal } from "../../../utils/calls.utils";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import { toast } from "react-toastify";
import TextArea from "../Input copy";

const ProjectDetails = ({onNext}) => {
  const [ProjectDetails,setProjectDetails] = useState({
    project:null,
    unit:{
        value:"",
        error:""
    },
    job_number:{
        value:"",
        error:""
    },
    mortgage_notes:{
        value:"",
        error:""
    }
  });

  const dispatch = useDispatch();
  let projects = useSelector(state=>state?.project);
  projects = projects?.map(project=>{return {name:project?._id,value:project?.name}});

  useEffect(()=>{
    dispatch(fetchAllProjectsAsyncThunk());

    const pd = localStorage.getItem("project_details");

    if(pd){
        setProjectDetails(JSON.parse(pd));
    }
    else{
        if(localStorage.getItem("deal_")){
            let deal = JSON.parse(localStorage.getItem("deal_"));

            setProjectDetails({
                project:{name:deal?.project?._id,value:deal?.project?.name},
                unit:{value:deal?.unit_no,error:""},
                job_number:{value:deal?.job_number,error:""},
                mortgage_notes:{value:deal?.mortgage_notes,error:""}
            })
        }
    }

  },[]);

  const onProjectChange = (e)=>{
    setProjectDetails(state=>{
        const newState = JSON.parse(JSON.stringify(state));
        newState.project = e;
        return newState;
    })
  }

  const onChange = (e)=>{
    
    setProjectDetails(state=>{
        const newState = JSON.parse(JSON.stringify(state));
        if(e?.target?.name=="unit"&&!isLengthGreaterThan(e?.target?.value,0)){
            newState[e?.target?.name].error="Unit No is required!";
        }
        else if(e?.target?.name=="unit"){
            newState[e?.target?.name].error="";
        }

        newState[e?.target?.name].value= e?.target?.value;

        return newState;
        
    })
    
  }

  const onSubmit = async (e)=>{
    try{
        if(ProjectDetails?.project==null||ProjectDetails?.unit?.error!=""||ProjectDetails?.unit?.value==""){
            return toast.error("Submission invalid!");
        }

        if(localStorage.getItem('is_deal_add')==="true")
            dispatch(showLoader("Creating new Deal, Please wait ..."));
        else
            dispatch(showLoader("Updating Deal, Please wait ..."));

        let res;
        if(localStorage.getItem('is_deal_add')==="true")
            res = await postDeal(ProjectDetails);
        else{
            let id = JSON.parse(localStorage.getItem('deal_'))?._id;

            const pd = new FormData();

            pd.append('unit_no',ProjectDetails?.unit?.value);
            pd.append('project',ProjectDetails?.project?.name);
            pd.append('job_number',ProjectDetails?.job_number?.value);
            pd.append('mortgage_notes',ProjectDetails?.mortgage_notes?.value);

            res = await updateDeal(pd,id);
        }

        localStorage.setItem("deal_",JSON.stringify(res?.data));
        if(localStorage.getItem('is_deal_add')=="true"){
            localStorage.setItem('is_deal_add',false);
        }
        localStorage.setItem("project_details",JSON.stringify(ProjectDetails));
        onNext();
        // navigate("/dashboard/deals");
    }catch(err){
        toast.error('Submission failed! Try again');
        console.log(err);
    }finally{
        dispatch(hideLoader());
    }
  }
  return (
    <div className={styles["form"]}>
                <div>
                    <P>
                    Please note if your project is not added in the system, first add project then initialize deal 
                    </P>
                </div>
                <div>
                    <SingleSelectDropdownv2 selected={ProjectDetails?.project?ProjectDetails?.project:{value:""}} onChange={onProjectChange} title={"Select Project"} options={projects} style={{justifyContent:"space-between"}}/>
                </div>
                <div className={styles["input"]}>
                        <Label>Unit No</Label>
                        <Input name="unit" error={ProjectDetails?.unit?.error} value={ProjectDetails?.unit?.value} onChange={onChange} type="text" placeholder="A12"/>
                </div>
                <div className={styles["input"]}>
                        <Label>Job Number</Label>
                        <Input name="job_number" error={ProjectDetails?.job_number?.error} value={ProjectDetails?.job_number?.value} onChange={onChange} type="text" placeholder=""/>
                </div>
                <div className={styles["input"]}>
                        <Label>Mortgage Notes</Label>
                        <TextArea name="mortgage_notes" error={ProjectDetails?.mortgage_notes?.error} value={ProjectDetails?.mortgage_notes?.value} onChange={onChange} type="text" placeholder="" style={{width:"100%",height:"100px"}}/>
                </div>
                <div>
                    <SubmitBtn text={localStorage.getItem("is_deal_add")=="true"?"Save And Next":"Update And Next"} onClick={onSubmit}/>
                </div>
            </div>
  )
}

export default ProjectDetails