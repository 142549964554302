export const isEmail=(email)=> {
    // Regular expression for a valid email address
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  
    // Test the input email against the regex pattern
    return emailRegex.test(email);
}

export const isLengthGreaterThan = (string,value)=>{
    return string.length > value;
}

export const isEqualTo = (string,value)=>{
    return string.length === value;
}

export const isDateMMDDYYYY = (value,allowFuture=false,allowPast=true)=>{
    const arr = value.split("-");

    if(arr.length!==3) return false;

    if(parseInt(arr[1])>12||parseInt(arr[1])<1) return false;

    if(parseInt(arr[2])>31||parseInt(arr[2])<1) return false;

    if(parseInt(arr[0])<1900) return false;

    if((new Date(value))>(new Date(Date.now()))&&(!allowFuture)) return false;
    if((new Date(value))<(new Date(Date.now()))&&(!allowPast)) return false;

    return true;
}
