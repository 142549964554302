import {  Outlet } from "react-router-dom";
import SideMenu from "../../elements/SideMenu";
import styles from "./index.module.css";
import Projects from "../../elements/Projects";
import { useEffect } from "react";
import { getMyProfile } from "../../../utils/calls.utils";

const Dashboard = (props)=>{
    return <div className={styles["container"]}>
        <SideMenu/>
        <Outlet/>
    </div>
}

export default Dashboard;