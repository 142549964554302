import { Link } from "react-router-dom";
import styles from "./index.module.css";

const NavItem = (props)=>{
    return <Link to={props.path} className={!props.isActive?styles["container"]:`${styles["container"]} ${styles["active"]}`}>
            <div className={styles["icon"]}>{props.icon}</div>
            {props.text}
    </Link>
}

export default NavItem;