import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { BsCheck } from 'react-icons/bs'

const Checkbox = ({name,onChange,size,defaultVal}) => {
  const [check, setCheck] = useState(defaultVal || false);

  const toggleCheck = (e)=>{
    setCheck(state=>!state);
    onChange(name,!check);
  }

  useEffect(()=>{
    setCheck(defaultVal);
  },[defaultVal]);

  return (
    <div name={name} onClick={toggleCheck} style={{height:`${size}px`,width:`${size}px`, backgroundColor:`${check?"var(--color_dark_1)":"rgba(255,255,255,0)"}`, color:`${check?"var(--color_light_1)":"rgba(255,255,255,0)"}`, transition:"all .1s"}} className={styles["container"]} >
        <BsCheck/>
    </div>
  )
}

export default Checkbox