import styles from "./index.module.css";

const TextArea =(props)=>{
    const readOnly = props.editable===undefined?false:!props.editable;

    return <><textarea 
    readOnly={readOnly}
    name={props.name || ""} 
    onBlur={props.onBlur || null} 
    style={props.style || {}} 
    className={styles["input"] || ""} 
    type={props.type || "text"} 
    placeholder={props.placeholder || ""} 
    value={props.value || ""} 
    onClick={props.onClick || null} 
    onChange={props.onChange || null}
    min={props?.min || ""}
    max={props?.max || ""}
    />
    <p
    className={styles["error"]} 
    style={props.errorStyle}
    >{props.error}</p>
    </>
}

export default TextArea;