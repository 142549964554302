import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllIncomes } from "./income.thunk";
import { toast } from "react-toastify";

const INIT_STATE = {
    incomes:[],
    meta:{}
}

export const fetchAllIncomesAsyncThunk = createAsyncThunk(
    "fetchIncomes",
    fetchAllIncomes
)


const IncomeSlice = createSlice({
    name:"income",
    initialState:INIT_STATE,
    reducers:{

    },
    extraReducers:(builders)=>{
        builders
        .addCase(fetchAllIncomesAsyncThunk.pending,(state)=>{
            return state;
        })
        .addCase(fetchAllIncomesAsyncThunk.rejected,(state)=>{
            toast.error("Fetching incomes failed");
            return state;
        })
        .addCase(fetchAllIncomesAsyncThunk.fulfilled,(state,action)=>{
            return action.payload.data;
        })
    }
})


export default IncomeSlice.reducer;