import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllExpenses } from "./expense.thunk";
import { toast } from "react-toastify";

const INIT_STATE = {
    expenses:[],
    meta:{}
}

export const fetchAllExpensesAsyncThunk = createAsyncThunk(
    "fetchExpenses",
    fetchAllExpenses
)


const ExpenseSlice = createSlice({
    name:"expense",
    initialState:INIT_STATE,
    reducers:{

    },
    extraReducers:(builders)=>{
        builders
        .addCase(fetchAllExpensesAsyncThunk.pending,(state)=>{
            return state;
        })
        .addCase(fetchAllExpensesAsyncThunk.rejected,(state)=>{
            toast.error("Fetching expenses failed");
            return state;
        })
        .addCase(fetchAllExpensesAsyncThunk.fulfilled,(state,action)=>{
            return action.payload.data;
        })
    }
})


export default ExpenseSlice.reducer;