import { BsDownload, BsLink45Deg, BsXCircle } from "react-icons/bs";
import H5 from "../../typography/H5";
import P from "../../typography/P";
import Input from "../Input";
import Label from "../Label";
import SingleSelectDropdownv2 from "../SingleSelectDropdownv2";
import SubmitBtn from "../SubmitBtn";
import styles from "./index.module.css";

import React, { useEffect, useState } from 'react'
import { fetchDealById, updateDeal } from "../../../utils/calls.utils";
import {useDispatch, useSelector} from "react-redux";
import { fetchAllAgentsAsyncThunk } from "../../../store/slices/agent/agent.slice";
import {showLoader,hideLoader} from "../../../store/slices/loader/loader.slice";
import { toast } from "react-toastify";
import Checkbox from "../Checkbox";

const AgentBox = ({idx,onDelete,agent,agentOptions,onDropdownChange,onChange,isAbsoluteToggle,isShowInNOSToggle})=>{

    const onDeleteHandler = (e)=>onDelete(idx);

    const agentTypeOptions = [
        { value:"main" },
        { value:"referral" },
        { value:"other" },
    ]

    const onTypeChange = (selected)=>{
        onDropdownChange("type",selected,idx);
    }
    const onAgentChange = (selected)=>{
        onDropdownChange("agent",selected,idx);
    }
    const onInfoChange = (e)=>{
        onChange(e,idx);
    }
    const onCheckboxChange = ()=>{
        isAbsoluteToggle({},idx);
    }
    const onIsShowInNOSToggle = ()=>{
        isShowInNOSToggle({},idx);
    }
    return <div className={styles["card"]}>
        <div>
            <div>
                <H5>Agent {idx}</H5>
                <div onClick={onDeleteHandler}>
                    {idx!==1 && <BsXCircle/>}
                </div>
            </div>
            <div className={styles["input"]}>
                <Label>Commission</Label>
                <Input 
                    type="text" 
                    placeholder="10.00" 
                    name="commission"
                    value={agent?.commission?.value}
                    error={agent?.commission?.error}
                    onChange={onInfoChange}
                />
            </div> 
            <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
                <div style={{display:"flex"}}><Checkbox defaultVal={agent?.isAbsolute} size={20} onChange={onCheckboxChange} name={"check"}/> <span style={{marginLeft:"calc(1 * var(--basex))"}}>Is commission absolute?</span></div>
            </div>
            <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
                <div style={{display:"flex"}}><Checkbox defaultVal={agent?.showInNOS} size={20} onChange={onIsShowInNOSToggle} name={"check"}/> <span style={{marginLeft:"calc(1 * var(--basex))"}}>Show in Notice Of Sale?</span></div>
            </div>
            <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
                <SingleSelectDropdownv2 onChange={onTypeChange} title="Select Agent Type" options={agentTypeOptions} selected={agent?.type} style={{justifyContent:"space-between"}}/>
            </div>
            <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
                <SingleSelectDropdownv2 onChange={onAgentChange} options={agentOptions} selected={agent?.agent} title="Select Agent" style={{justifyContent:"space-between"}}/>
            </div>            
        </div>
    </div>
}
const AgentDetails = ({onNext}) => {

  const dispatch = useDispatch();

  const [agents,setAgents]=useState([]);
  const [agentOptions,setAgentOptions] = useState([]);

  const agent = useSelector(state=>state.agent.agents);

  useEffect(()=>{
    dispatch(fetchAllAgentsAsyncThunk());
  },[]);
  
  useEffect(()=>{
    if(!agent) return;

    const options = [];

    for(let i=0;i<agent?.length;i++){
        options.push({value:agent[i]?.name,name:agent[i]?._id});
    }

    setAgentOptions(options);
  },[agent]);

  useEffect(()=>{

    if(agents?.length > 0||agentOptions?.length ==0) return;

    let dealId = localStorage.getItem("deal_");

    if(!dealId) return toast.error("Please goto dashboard and come back");

    dealId = JSON.parse(dealId)?._id;

    fetchDealById(dealId).then(res=>{
        let agentsData = [];

        if(res?.data?.agent_details?.length==0){
            agentsData.push({
                agent:null,
                type:{value:'main'},
                commission:{value:0,error:""},
                isAbsolute:false,
                showInNOS:true
            },
            {
                agent:null,
                type:null,
                commission:{value:0,error:""},
                isAbsolute:false,
                showInNOS:true
            },
            )
        }
        else{
            agentsData = res?.data?.agent_details?.map(agent=>{
                let el= agentOptions.find(el=>el?.name==agent?.agent);

                return {
                    agent:el,
                    type:{value:agent?.type},
                    commission:{
                        value:agent?.commission,
                        error:""
                    },
                    isAbsolute:agent?.isAbsolute,
                    showInNOS:agent?.showInNOS
                }
            });
        }
        setAgents(agentsData);
        // toggleReload();
    }).catch(err=>{
        console.log(err);;
        toast.error("Unable to fetch previous data, try again")
    });

  },[agentOptions?.length]);

  const onAddAgent = ()=>{
    setAgents([
        ...agents,
        {
            agent:null,
            type:null,
            commission:{value:0,error:""},
            isAbsolute:false,
            showInNOS:true
        },
    ])
  }

  const onDeleteAgent = (idx)=>{
     const newAgents = agents?.filter((agent,i)=>i!=idx);
     setAgents(newAgents);
  }

  const onDropdownChange = (name,value,idx)=>{
    const newAgents = [...agents];

    newAgents[idx][name]=value;

    setAgents(newAgents);
  }

  const onChange = (e,idx)=>{
    const newAgent = JSON.parse(JSON.stringify(agents));

    const name= e?.target?.name;

    if(!name) return toast.error("Unable to change, try again!");

    if(name=="commission"&&!newAgent[idx]?.isAbsolute&&((parseFloat(e?.target?.value)<0)||parseFloat(e?.target?.value)>100)){
        newAgent[idx][name].error="Invalid Commission!"
    }
    else{
        newAgent[idx][name].error=""
    }

    newAgent[idx][name].value = e?.target?.value;

    setAgents(newAgent);
  }

  const isAbsoluteToggle = (e,idx)=>{
    const newAgent = JSON.parse(JSON.stringify(agents));

    newAgent[idx]["isAbsolute"]=!newAgent[idx]["isAbsolute"];

    if(newAgent[idx]?.commission?.value>100&&!newAgent[idx]["isAbsolute"]) newAgent[idx].commission.error="Invalid Commission!";
    if(newAgent[idx]["isAbsolute"]) newAgent[idx].commission.error="";

    setAgents(newAgent);
  }

  const isShowInNOSToggle = (e,idx)=>{
    const newAgent = JSON.parse(JSON.stringify(agents));

    newAgent[idx]["showInNOS"]=!newAgent[idx]["showInNOS"];

    setAgents(newAgent);
  }

  const onSubmit = async (e)=>{
    let totalCommissionPer = 0,totalCommissionAbs = 0;
    for(let i=0;i<agents?.length;i++){
      if(i==0&&(!agents[i]?.agent)) return toast.error("Invalid submission!");
      else if(i!=0&&((!agents[i]?.agent)||(!agents[i]?.type)||(agents[i]?.commission?.value=="")||(agents[i]?.commission?.error!=""))) return toast.error("Invalid submission!");
    
      if(i!==0){
        if(!agents[i]?.isAbsolute)
        totalCommissionPer+=parseFloat(agents[i]?.commission?.value);
        else
        totalCommissionAbs+=parseFloat(agents[i]?.commission?.value);
      }
    }


    try{
      let dealId = localStorage.getItem("deal_");

      if(!dealId) return toast.error("Please goto dashboard and come back!");

      let total_commission = JSON.parse(dealId)?.total_commission;
      dealId = JSON.parse(dealId)?._id;
      

      dispatch(showLoader('Updating agents data! Please wait'));

      const data = new FormData();

      for(let i=0;i<agents?.length;i++){
        const cur = {...agents[i]};

        if(i==0&&total_commission) {
            cur.commission = {value:(parseFloat(total_commission/100)*(100 - totalCommissionPer))-totalCommissionAbs};
            cur.isAbsolute = true;
        }

        if(cur?.agent){
            data.append(`agent_details[${i}][agent]`,cur?.agent?.name);
        }
        if(cur?.type){
            data.append(`agent_details[${i}][type]`,cur?.type?.value);
        }
        if(cur?.commission?.value){
            data.append(`agent_details[${i}][commission]`,cur?.commission?.value);
        }
        if(typeof cur?.isAbsolute=="boolean"){
            data.append(`agent_details[${i}][isAbsolute]`,cur?.isAbsolute);
        }
        if(typeof cur?.showInNOS=="boolean"){
            data.append(`agent_details[${i}][showInNOS]`,cur?.showInNOS);
        }
      }

      const res = await updateDeal(data,dealId);

      onNext();
    }catch(err){
      console.log(err);
      toast.error("Submission unsuccesfull!")
    }finally{
        dispatch(hideLoader());
    }
  }

  return (
    <div className={styles["form"]}>
                <div className={styles["input"]}>
                    <Label>Main Agent</Label>
                    <SingleSelectDropdownv2 options={agentOptions} selected={agents[0]?.agent} onChange={(element)=>onDropdownChange("agent",element,0)} title="Select Main Agent" style={{justifyContent:"space-between"}}/>
                </div>
                <div>
                    <SubmitBtn text="Add Agent" onClick={onAddAgent} style={{marginBottom:"calc(3 * var(--basey))"}}/>
                </div>
                <div>
                    { agents && agents?.map((agent,idx)=>{
                        if(idx==0) return <></>;

                        return <AgentBox 
                        key={idx} 
                        idx={idx} 
                        agent={agent}
                        onDelete={onDeleteAgent}
                        onDropdownChange={onDropdownChange}
                        agentOptions={agentOptions}
                        onChange={onChange}
                        isAbsoluteToggle={isAbsoluteToggle}
                        isShowInNOSToggle={isShowInNOSToggle}
                        />
                    })}
                </div>
                <div>
                    <SubmitBtn onClick={onSubmit} text="Save And Next"/>
                </div>
            </div>
  )
}

export default AgentDetails