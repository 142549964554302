import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllAgents } from "./agent.thunk";
import { toast } from "react-toastify";

const INIT_STATE = {
    agents:[],
    meta:{}
}

export const fetchAllAgentsAsyncThunk = createAsyncThunk(
    "fetchAgents",
    fetchAllAgents
)


const AgentSlice = createSlice({
    name:"agent",
    initialState:INIT_STATE,
    reducers:{

    },
    extraReducers:(builders)=>{
        builders
        .addCase(fetchAllAgentsAsyncThunk.pending,(state)=>{
            return state;
        })
        .addCase(fetchAllAgentsAsyncThunk.rejected,(state)=>{
            toast.error("Fetching agents failed!");
            return state;
        })
        .addCase(fetchAllAgentsAsyncThunk.fulfilled,(state,action)=>{
            return action.payload.data;
        })
    }
})


export default AgentSlice.reducer;