import { BsArrowLeftCircle } from "react-icons/bs";
import styles from "./index.module.css";

import React, { useEffect, useState } from 'react'
import H1 from "../../typography/H1";
import P from "../../typography/P";
import SingleSelectDropdown from "../../elements/SingleSelectDropdown";
import Label from "../../elements/Label";
import Input from "../../elements/Input";
import SubmitBtn from "../../elements/SubmitBtn";
import SingleSelectDropdownv2 from "../../elements/SingleSelectDropdownv2";
import ProjectDetails from "../../elements/ProjectDetails";
import ProjectInfo from "../../elements/ProjectInfo";
import OfferFinancials from "../../elements/OfferFinancials";
import AgentDetails from "../../elements/AgentDetails";
import DocumentDetails from "../../elements/DocumentDetails";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoanDetails from "../../elements/LoanDetails";

const DealForm = () => {
  const [active, setActive] = useState(0);
  const [status,setStatus] = useState(null);
  const [user,setUser] = useState({});

  useEffect(()=>{
    if(localStorage.getItem("user_")) setUser(JSON.parse(localStorage.getItem("user_")));
  },[]);

  const navigate = useNavigate();
  const onNext= ()=>{
    setActive(state=>state+1);
  }

  const Forms = [
  <ProjectDetails onNext={onNext}/>,
  <ProjectInfo onNext={onNext}/>,
  <OfferFinancials onNext={onNext}/>,
  <AgentDetails onNext={onNext}/>,
  <LoanDetails onNext={onNext}/>,
  <DocumentDetails onNext={onNext}/>
  ];

  const onDiscard = ()=>{
    navigate("/dashboard/deals");
  }
  
  useEffect(()=>{
    let deal = localStorage.getItem("deal_");

    if(!deal) return setStatus('open');

    deal = JSON.parse(deal);

    if(deal?.status)
    setStatus(deal?.status);
    else
    setStatus('open');

  },[]);
  return (
    <div className={styles["container"]}>
        <div className={styles["wrapper"]}>
            <div onClick={onDiscard} style={{cursor:"pointer"}}>
                <BsArrowLeftCircle/>
            </div>
            <div>
                <H1>Deal Details</H1>
            </div>
            <div className={styles["nav"]}>
                <div>
                    <span onClick={()=>setActive(0)} className={active==0?styles["active"]:""}>
                        Project Details
                    </span>
                    <span onClick={()=>setActive(1)} className={active==1?styles["active"]:""}>
                        Purchaser Info
                    </span>
                    <span onClick={()=>{
                        if(status!="open")
                            setActive(2)
                        else{
                            toast.error("Please change status to initialized from dashboard!");
                        }
                    }} className={active==2?styles["active"]:""}>
                        Offer Financials
                    </span>
                    {user?.role =="admin" && <span onClick={()=>{
                        if(status!="open")
                            setActive(3)
                        else{
                            toast.error("Please change status to initialized from dashboard!");
                        }
                    }} className={active==3?styles["active"]:""}>
                        Agent Details
                    </span>}
                    {user?.role =="admin" && <span onClick={()=>{
                        if(status!="open")
                            setActive(4)
                        else{
                            toast.error("Please change status to initialized from dashboard!");
                        }
                    }} className={active==4?styles["active"]:""}>
                        Loan Details
                    </span>}
                    {user?.role =="admin" && <span onClick={()=>{
                        if(status!="open")
                            setActive(5)
                        else{
                            toast.error("Please change status to initialized from dashboard!");
                        }
                    }} className={active==5?styles["active"]:""}>
                        Documents
                    </span>}
                </div>
            </div>
            {Forms[active]}
        </div>
    </div>
  )
}

export default DealForm