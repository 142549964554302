export const loginHandler = async (__,thunkApi)=>{
    try{
        const api = thunkApi.extra.apiService;

        const res = await api.default.post('/user/login',{
            email:__.email.value,
            password:__.password.value
        });

        return res;
    }
    catch(err){
        console.log(err);
        return Promise.reject(err);
    }
};

