import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_DOMAIN;

export const defaultAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
  },
});

defaultAxios.interceptors.response.use(
  (response) => {
    if (response?.status === 200||response?.status === 201||response?.status === 204) return response.data;
    return Promise.reject(response.data?.err);
  },
  (error) => {
    const { response } = error;

    const message = response?.data?.message || "Something Went Wrong";
    return Promise.reject(message);
  }
);