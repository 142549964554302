import { BsChevronDown } from "react-icons/bs";
import styles from "./index.module.css";

import React, { useEffect, useState } from 'react'

const SingleSelectDropdownv2 = (props) => {

  const [expand,setExpand] = useState(false);
  const [selected,setSelected] = useState({value:""});
  const [options,setOptions] = useState([]);
  
  useEffect(()=>{
    if(props.options)
    {
      setOptions(props.options);
    }

    if(props.selected)
    {
      setSelected(props.selected);
    }
  },[props.options,props.selected]);

  const toggleExpand = ()=>{
    setExpand(state=>!state);
  }

  const onSelect = (e)=>{
    let sltd;
    if(e?.target?.dataset?.name)
    sltd = options[e?.target?.dataset?.name];
    else if(e?.target?.parentNode?.dataset?.name)
    sltd = options[e?.target?.parentNode?.dataset?.name];

    if(sltd)
    {
      setSelected(sltd);
      if(props?.onChange)
      props?.onChange(sltd);

      toggleExpand();
    }
  }

  return (
    <div className={styles["container"]}>
        <div className={styles["label"]} style={props.style} onClick={toggleExpand}>{selected?.value==""?props?.title:selected?.value}<div style={{transform:`rotate(${expand?"180deg":"0deg"})`}}><BsChevronDown/></div></div> 
        <div className={styles["options"]} style={{transform:`scale(${expand?"1":"0"})`, transformOrigin:"top"}}>
            {
              options && options?.map((option,key)=>{
                return <div className={styles["option"]} key={key} data-name={key} onClick={onSelect}>
                  <div>{option?.value}</div>
                </div>
              })
            }
        </div>
    </div>
  )
}

export default SingleSelectDropdownv2