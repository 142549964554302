import { BsXCircle } from "react-icons/bs";
import H5 from "../../typography/H5";
import Input from "../Input";
import Label from "../Label";
import SingleSelectDropdownv2 from "../SingleSelectDropdownv2";
import SubmitBtn from "../SubmitBtn";
import styles from "./index.module.css";

import React, { useEffect, useState } from 'react'
import { fetchDealById, updateDeal } from "../../../utils/calls.utils";
import {useDispatch, useSelector} from "react-redux";
import { fetchAllAgentsAsyncThunk } from "../../../store/slices/agent/agent.slice";
import {showLoader,hideLoader} from "../../../store/slices/loader/loader.slice";
import { toast } from "react-toastify";

const AgentBox = ({idx,onDelete,agent,agentOptions,onDropdownChange,onChange})=>{

    const onDeleteHandler = (e)=>onDelete(idx);

    const onFromAgentChange = (selected)=>{
        onDropdownChange("from_agent",selected,idx);
    }
    const onToAgentChange = (selected)=>{
        onDropdownChange("to_agent",selected,idx);
    }
    const onInfoChange = (e)=>{
        onChange(e,idx);
    }
    return <div className={styles["card"]}>
        <div>
            <div>
                <H5>Loan Entry {idx+1}</H5>
                <div onClick={onDeleteHandler}>
                    <BsXCircle/>
                </div>
            </div>
            <div className={styles["input"]}>
                <Label>Loan Amount</Label>
                <Input 
                    type="text" 
                    placeholder="10.00" 
                    name="amount"
                    value={agent?.amount?.value}
                    error={agent?.amount?.error}
                    onChange={onInfoChange}
                />
            </div> 
            <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
                <SingleSelectDropdownv2 onChange={onFromAgentChange} title="Select From Agent" options={agentOptions} selected={agent?.from_agent} style={{justifyContent:"space-between"}}/>
            </div>
            <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
                <SingleSelectDropdownv2 onChange={onToAgentChange} title="Select To Agent" options={agentOptions} selected={agent?.to_agent} style={{justifyContent:"space-between"}}/>
            </div>            
        </div>
    </div>
}
const LoanDetails = ({onNext}) => {

  const dispatch = useDispatch();

  const [agents,setAgents]=useState([]);
  const [agentOptions,setAgentOptions] = useState([]);

  const agent = useSelector(state=>state.agent.agents);

  useEffect(()=>{
    dispatch(fetchAllAgentsAsyncThunk());
  },[]);
  
  useEffect(()=>{
    if(!agent) return;

    const options = [];

    for(let i=0;i<agent?.length;i++){
        options.push({value:agent[i]?.name,name:agent[i]?._id});
    }

    setAgentOptions(options);
  },[agent]);

  useEffect(()=>{

    if(agents?.length > 0||agentOptions?.length ==0) return;

    let dealId = localStorage.getItem("deal_");

    if(!dealId) return toast.error("Please goto dashboard and come back");

    dealId = JSON.parse(dealId)?._id;

    fetchDealById(dealId).then(res=>{
        let agentsData = [];

        if(res?.data?.loan_details?.length==0){
            agentsData.push(
            {
                from_agent:null,
                to_agent:null,
                amount:{value:0,error:""}
            },
            )
        }
        else{
            agentsData = res?.data?.loan_details?.map(agent=>{
                let from= agentOptions.find(el=>el?.name==agent?.from_agent);
                let to= agentOptions.find(el=>el?.name==agent?.to_agent);

                return {
                    from_agent:from,
                    to_agent:to,
                    amount:{value:agent?.amount,error:""}
                }
            });
        }
        setAgents(agentsData);
        // toggleReload();
    }).catch(err=>{
        console.log(err);;
        toast.error("Unable to fetch previous data, try again")
    });

  },[agentOptions?.length]);

  const onAddAgent = ()=>{
    setAgents([
        ...agents,
        {
            from_agent:null,
            to_agent:null,
            amount:{value:0,error:""}
        },
    ])
  }

  const onDeleteAgent = (idx)=>{
     const newAgents = agents?.filter((agent,i)=>i!=idx);
     setAgents(newAgents);
  }

  const onDropdownChange = (name,value,idx)=>{
    const newAgents = [...agents];

    newAgents[idx][name]=value;

    setAgents(newAgents);
  }

  const onChange = (e,idx)=>{
    const newAgent = JSON.parse(JSON.stringify(agents));

    const name= e?.target?.name;

    if(!name) return toast.error("Unable to change, try again!");

    if(name=="amount"&&e?.target?.value==""){
        newAgent[idx][name].error="Invalid Amount!"
    }
    else{
        newAgent[idx][name].error=""
    }

    newAgent[idx][name].value = e?.target?.value;

    setAgents(newAgent);
  }

  const onSubmit = async (e)=>{
    for(let i=0;i<agents?.length;i++){
      if(agents[i]?.amount?.value==""||agents[i]?.amount?.error!=""||!agents[i]?.from_agent||!agents[i]?.to_agent) return toast.error("Invalid submission!");
    }

    try{
      let dealId = localStorage.getItem("deal_");

      if(!dealId) return toast.error("Please goto dashboard and come back!");

      dealId = JSON.parse(dealId)?._id;
      
      dispatch(showLoader('Updating agents data! Please wait'));

      const data = new FormData();

      if(agents?.length==0){
        data.append('loan_details',null)
      }

      for(let i=0;i<agents?.length;i++){
        const cur = {...agents[i]};

        if(cur?.from_agent){
            data.append(`loan_details[${i}][from_agent]`,cur?.from_agent?.name);
        }
        if(cur?.to_agent){
            data.append(`loan_details[${i}][to_agent]`,cur?.to_agent?.name);
        }
        if(cur?.amount?.value){
            data.append(`loan_details[${i}][amount]`,cur?.amount?.value);
        }
      }

      const res = await updateDeal(data,dealId);

      onNext();
    }catch(err){
      console.log(err);
      toast.error("Submission unsuccesfull!")
    }finally{
        dispatch(hideLoader());
    }
  }

  return (
    <div className={styles["form"]}>
                <div>
                    <SubmitBtn text="Add Loan Entry" onClick={onAddAgent} style={{marginBottom:"calc(3 * var(--basey))"}}/>
                </div>
                <div>
                    { agents && agents?.map((agent,idx)=>{
                        return <AgentBox 
                        key={idx} 
                        idx={idx} 
                        agent={agent}
                        onDelete={onDeleteAgent}
                        onDropdownChange={onDropdownChange}
                        agentOptions={agentOptions}
                        onChange={onChange}
                        />
                    })}
                </div>
                <div>
                    <SubmitBtn onClick={onSubmit} text="Save And Next"/>
                </div>
            </div>
  )
}

export default LoanDetails