import { useSelector } from "react-redux";
import H5 from "../../typography/H5";
import styles from "./index.module.css";

const Loader = ()=>{
    const loader = useSelector(state=>state.loader);

    return <div className={styles["container"]}>
        <span className={styles["loader"]}></span>
        <H5>{loader?.loaderMessage}</H5>
    </div>;
}

export default Loader;