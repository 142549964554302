import H2 from "../../typography/H2";
import styles from "./index.module.css";

const Modal = (props)=>{
    return <div className={styles["container"]}>
        <div className={styles["wrapper"]}>
            <div><H2>{props.message}</H2></div>
            <div>
                <span onClick={props.onAction}>{props.action}</span>
                <span onClick={props.onClose}>Close</span>
            </div>
        </div>
    </div>
}

export default Modal;