const { createSlice } = require("@reduxjs/toolkit")

const INIT_STATE={
    showLoader:false,
    loaderMessage:""
}

const loaderSlice = createSlice({
    name:"Loader",
    initialState:INIT_STATE,
    reducers:{
        showLoader:(state,action)=>{
            const newState = JSON.parse(JSON.stringify(state));
            newState.showLoader = true;
            newState.loaderMessage = action?.payload;
            return newState;
        },
        hideLoader:(state)=>{
            const newState = JSON.parse(JSON.stringify(state));
            newState.showLoader = false;
            newState.loaderMessage = "";
            return newState;
        }
    }
})

export const {showLoader, hideLoader} = loaderSlice.actions;

export default loaderSlice.reducer;