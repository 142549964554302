import { BsDownload, BsLink45Deg } from "react-icons/bs";
import H5 from "../../typography/H5";
import P from "../../typography/P";
import Input from "../Input";
import Label from "../Label";
import SingleSelectDropdownv2 from "../SingleSelectDropdownv2";
import SubmitBtn from "../SubmitBtn";
import styles from "./index.module.css";

import React, { useEffect, useRef, useState } from 'react'
import { isEmail, isLengthGreaterThan } from "../../../utils/validators";
import { fetchDealById, fetchDealDocumentById, updateDeal } from "../../../utils/calls.utils";
import { useDispatch } from "react-redux";
import {hideLoader, showLoader} from "../../../store/slices/loader/loader.slice"
import {useNavigate} from "react-router-dom";
import { redirectUsingFileName } from "../../../utils/download.utils";
import { toast } from "react-toastify";

const DocumentDetails = () => {
  const fileRef = useRef();
  const execRef = useRef();
  const other1Ref = useRef();
  const other2Ref = useRef();
  const other3Ref = useRef();
  const other4Ref = useRef();
  const other5Ref = useRef();


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dsSigned,setDsSigned] = useState(null);
  const [exec,setExec] = useState(null);
  const [doc1,setDoc1] = useState(null);
  const [doc2,setDoc2] = useState(null);
  const [doc3,setDoc3] = useState(null);
  const [doc4,setDoc4] = useState(null);
  const [doc5,setDoc5] = useState(null);


  const [data,setData] = useState({
    ds_email:{
        value:"",
        error:""
    },
    other_doc_label_1:{
        value:"",
        error:""
    },
    other_doc_label_2:{
        value:"",
        error:""
    },
    other_doc_label_3:{
        value:"",
        error:""
    },
    other_doc_label_4:{
        value:"",
        error:""
    },
    other_doc_label_5:{
        value:"",
        error:""
    },
    ds_signed:null,
    completed_execulted_doc:null,
    other_doc_1:null,
    other_doc_2:null,
    other_doc_3:null,
    other_doc_4:null,
    other_doc_5:null,
  });

  useEffect(()=>{
    let dealId = localStorage.getItem("deal_");

    if(!dealId) return toast.error("Go to dashboard and come back!");

    dealId = JSON.parse(dealId)?._id;

    fetchDealById(dealId)
    .then(res=>{
        const ds_data = res?.data;

        const newState = {...data};

        newState.ds_email.value = ds_data?.ds_email;
        newState.other_doc_label_1.value = ds_data?.other_doc_label_1;
        newState.other_doc_label_2.value = ds_data?.other_doc_label_2;
        newState.other_doc_label_3.value = ds_data?.other_doc_label_3;
        newState.other_doc_label_4.value = ds_data?.other_doc_label_4;
        newState.other_doc_label_5.value = ds_data?.other_doc_label_5;

        setDsSigned(ds_data?.ds_signed || null);
        setExec(ds_data?.completed_execulted_doc || null);
        setDoc1(ds_data?.other_doc_1 || null);
        setDoc2(ds_data?.other_doc_2 || null);
        setDoc3(ds_data?.other_doc_3 || null);
        setDoc4(ds_data?.other_doc_4 || null);
        setDoc5(ds_data?.other_doc_5 || null);


        setData(newState);
    })
    .catch(err=>{
        console.log(err);
        toast.error("Fetching deal failed")
    })
  },[]);
  const onChange = (e,isFile=false)=>{

    setData(state=>{
        const newState = JSON.parse(JSON.stringify(state));

        newState.ds_signed = state.ds_signed;
        newState.completed_execulted_doc = state.completed_execulted_doc;
        newState.other_doc_1 = state.other_doc_1;
        newState.other_doc_2 = state.other_doc_2;
        newState.other_doc_3 = state.other_doc_3;
        newState.other_doc_4 = state.other_doc_4;
        newState.other_doc_5 = state.other_doc_5;


        let name = e?.target?.name;

        newState.ds_signed = state.ds_signed;
        newState.completed_execulted_doc = state.completed_execulted_doc;

        if(name=="ds_email"&&((!isLengthGreaterThan(e?.target?.value,0)))){
            newState[name].error = ""
        }
        else if(name=="ds_email"&&((!isEmail(e?.target?.value)))){
            newState[name].error = "Invalid Email"
        }
        else if(name=="ds_email"){
            newState[name].error ="";
        }

        if(isFile)
        newState[name] = e?.target?.files[0];
        else
        newState[name].value = e?.target?.value;

        return newState;
    })
    
  }

  const onFileUploadClickHandler = ()=>fileRef?.current?.click();
  const onExecUploadClickHandler = ()=>execRef?.current?.click();
  const onOther1ClickHandler = ()=>other1Ref?.current?.click();
  const onOther2ClickHandler = ()=>other2Ref?.current?.click();
  const onOther3ClickHandler = ()=>other3Ref?.current?.click();
  const onOther4ClickHandler = ()=>other4Ref?.current?.click();
  const onOther5ClickHandler = ()=>other5Ref?.current?.click();



  const onSubmit = async (e)=>{
    try{

        dispatch(showLoader("Updating deal secretery and documents! Please Wait!"));

        let dealId = localStorage.getItem("deal_");

        if(!dealId) return toast.error("Please go to dashboard and come back!");

        dealId = JSON.parse(dealId)?._id;

        const postData = new FormData();

        if(data?.ds_email?.value)
        postData.append('ds_email',data?.ds_email?.value);

        if(data?.other_doc_label_1?.value)
        postData.append('other_doc_label_1',data?.other_doc_label_1?.value);

        if(data?.other_doc_label_2?.value)
        postData.append('other_doc_label_2',data?.other_doc_label_2?.value);

        if(data?.other_doc_label_3?.value)
        postData.append('other_doc_label_3',data?.other_doc_label_3?.value);

        if(data?.other_doc_label_4?.value)
        postData.append('other_doc_label_4',data?.other_doc_label_4?.value);

        if(data?.other_doc_label_5?.value)
        postData.append('other_doc_label_5',data?.other_doc_label_5?.value);


        if(data?.ds_signed && typeof data?.ds_signed !== "string")
        postData.append('ds_signed',data?.ds_signed);

        if(data?.other_doc_1 && typeof data?.other_doc_1 !== "string")
        postData.append('other_doc_1',data?.other_doc_1);

        if(data?.other_doc_2 && typeof data?.other_doc_2 !== "string")
        postData.append('other_doc_2',data?.other_doc_2);

        if(data?.other_doc_3 && typeof data?.other_doc_3 !== "string")
        postData.append('other_doc_3',data?.other_doc_3);

        if(data?.other_doc_4 && typeof data?.other_doc_4 !== "string")
        postData.append('other_doc_4',data?.other_doc_4);

        if(data?.other_doc_5 && typeof data?.other_doc_5 !== "string")
        postData.append('other_doc_5',data?.other_doc_5);

        if(data?.completed_execulted_doc && typeof data?.completed_execulted_doc !== "string")
        postData.append('completed_execulted_doc',data?.completed_execulted_doc);

        const res = await updateDeal(postData,dealId);

        navigate("/dashboard/deals");
    }catch(err){
        console.log(err);
        toast.error("Submission failed! Try again");
    }
    finally{
        dispatch(hideLoader());
    }
  }

  const onDownload =async (name)=>{
    if(name=="ds")
    await redirectUsingFileName(dsSigned);
    else if(name=="exec")
    await redirectUsingFileName(exec);
    else if(name=="1")
    await redirectUsingFileName(doc1);
    else if(name=="2")
    await redirectUsingFileName(doc2);
    else if(name=="3")
    await redirectUsingFileName(doc3);
    else if(name=="4")
    await redirectUsingFileName(doc4);
    else if(name=="5")
    await redirectUsingFileName(doc5);
  }

  const unsignedDocClick =async (e)=>{
    try{
        dispatch(showLoader("Preparing Document, Please wait .."));
        let dealId = localStorage.getItem("deal_");

        if(!dealId) return toast.error("Go to dashboard and come back!");
    
        dealId = JSON.parse(dealId)?._id;

        await fetchDealDocumentById(dealId);

        window.open(process.env.REACT_APP_API_DOMAIN+"/deal/document/deal?dealId="+dealId,"_blank");

    }catch(err){
        toast.error(err);
    }finally{
        dispatch(hideLoader());
    }
  } 

  return (
    <div className={styles["form"]}>
                <div>
                    <P>
                    Please download deal secretary document from below and reupload it after it’s signed for future reference
                    </P>
                </div>
                <div className={styles["input"]}>
                        <Label>Deal Secretary Email</Label>
                        <Input 
                            type="text" 
                            placeholder="dsemail@gmail.com"
                            name="ds_email"
                            value={data?.ds_email?.value}
                            error={data?.ds_email?.error}
                            onChange={onChange}
                        />
                        </div>
                <div>
                   <input name="ds_signed" style={{display:"none",visibility:"hidden"}} type="file" ref={fileRef} onChange={(e)=>onChange(e,true)}/>
                   <input name="completed_execulted_doc" style={{display:"none",visibility:"hidden"}} type="file" ref={execRef} onChange={(e)=>onChange(e,true)}/>
                   <input name="other_doc_1" style={{display:"none",visibility:"hidden"}} type="file" ref={other1Ref} onChange={(e)=>onChange(e,true)}/>
                   <input name="other_doc_2" style={{display:"none",visibility:"hidden"}} type="file" ref={other2Ref} onChange={(e)=>onChange(e,true)}/>
                   <input name="other_doc_3" style={{display:"none",visibility:"hidden"}} type="file" ref={other3Ref} onChange={(e)=>onChange(e,true)}/>
                   <input name="other_doc_4" style={{display:"none",visibility:"hidden"}} type="file" ref={other4Ref} onChange={(e)=>onChange(e,true)}/>
                   <input name="other_doc_5" style={{display:"none",visibility:"hidden"}} type="file" ref={other5Ref} onChange={(e)=>onChange(e,true)}/>
                   <div className={styles["documentBox"]}>
                        <div>
                            <H5>Unsigned Notice Of Sale</H5>
                        </div>
                        <div>
                          <BsDownload style={{fontSize:"2rem",color:"rgba(0,0,0,0)"}}/>
                       </div>
                        <div onClick={unsignedDocClick}>
                            <BsDownload style={{fontSize:"2rem"}}/>
                        </div>
                    </div>
                </div>
                <div>
                   <div className={styles["documentBox"]}>
                        <div>
                            <H5>Signed Notice Of Sale</H5>
                        </div>
                        <div>
                            {dsSigned && <BsDownload onClick={()=>onDownload("ds")}/>}
                       </div>
                        <div onClick={onFileUploadClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{data?.ds_signed?.name}</div>
                </div>
                <div>
                   <div className={styles["documentBox"]}>
                        <div>
                            <H5>Completed Executed Document</H5>
                        </div>
                        <div>
                            {exec && <BsDownload onClick={()=>onDownload("exec")}/>}
                       </div>
                        <div onClick={onExecUploadClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{data?.completed_execulted_doc?.name}</div>
                </div>
                <div>
                   <div className={styles["documentBox"]}>
                        <div>
                            <Input 
                                type="text" 
                                placeholder="Label 1"
                                name="other_doc_label_1"
                                value={data?.other_doc_label_1?.value}
                                error={data?.other_doc_label_1?.error}
                                onChange={onChange}
                                style={{width:"90%"}}
                            />
                        </div>
                        <div>
                            {doc1 && <BsDownload onClick={()=>onDownload("1")}/>}
                       </div>
                        <div onClick={onOther1ClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{data?.other_doc_1?.name}</div>
                </div>
                <div>
                   <div className={styles["documentBox"]}>
                        <div>
                            <Input 
                                type="text" 
                                placeholder="Label 2"
                                name="other_doc_label_2"
                                value={data?.other_doc_label_2?.value}
                                error={data?.other_doc_label_2?.error}
                                onChange={onChange}
                                style={{width:"90%"}}
                            />
                        </div>
                        <div>
                            {doc2 && <BsDownload onClick={()=>onDownload("2")}/>}
                       </div>
                        <div onClick={onOther2ClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{data?.other_doc_2?.name}</div>
                </div>
                <div>
                   <div className={styles["documentBox"]}>
                        <div>
                            <Input 
                                type="text" 
                                placeholder="Label 3"
                                name="other_doc_label_3"
                                value={data?.other_doc_label_3?.value}
                                error={data?.other_doc_label_3?.error}
                                onChange={onChange}
                                style={{width:"90%"}}
                            />
                        </div>
                        <div>
                            {doc3 && <BsDownload onClick={()=>onDownload("3")}/>}
                       </div>
                        <div onClick={onOther3ClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{data?.other_doc_3?.name}</div>
                </div>
                <div>
                   <div className={styles["documentBox"]}>
                        <div>
                            <Input 
                                type="text" 
                                placeholder="Label 4"
                                name="other_doc_label_4"
                                value={data?.other_doc_label_4?.value}
                                error={data?.other_doc_label_4?.error}
                                onChange={onChange}
                                style={{width:"90%"}}
                            />
                        </div>
                        <div>
                            {doc4 && <BsDownload onClick={()=>onDownload("4")}/>}
                       </div>
                        <div onClick={onOther4ClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{data?.other_doc_4?.name}</div>
                </div>
                <div>
                   <div className={styles["documentBox"]}>
                        <div>
                            <Input 
                                type="text" 
                                placeholder="Label 5"
                                name="other_doc_label_5"
                                value={data?.other_doc_label_5?.value}
                                error={data?.other_doc_label_5?.error}
                                onChange={onChange}
                                style={{width:"90%"}}
                            />
                        </div>
                        <div>
                            {doc5 && <BsDownload onClick={()=>onDownload("5")}/>}
                       </div>
                        <div onClick={onOther5ClickHandler}>
                            <BsLink45Deg/>
                        </div>
                    </div>
                    <div className={styles["filename"]}>{data?.other_doc_5?.name}</div>
                </div>
                <div>
                    <SubmitBtn onClick={onSubmit} text="Save And Exit"/>
                </div>
            </div>
  )
}

export default DocumentDetails