import { useNavigate } from "react-router-dom";
import { loginAsyncThunk } from "../../../store/slices/auth/auth.slice";
import { isEmail, isLengthGreaterThan } from "../../../utils/validators";
import Form from "../../elements/Form1";
import Input from "../../elements/Input";
import Label from "../../elements/Label";
import SubmitBtn from "../../elements/SubmitBtn";
import H1 from "../../typography/H1";
import H2 from "../../typography/H2";
import H5 from "../../typography/H5";
import styles from "./index.module.css";

import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import { getMyProfile } from "../../../utils/calls.utils";
import { toast } from "react-toastify";

const Login = (props)=>{
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state=>state.auth.isLoggedIn);
    const navigate = useNavigate();

    useEffect(()=>{
        if(isLoggedIn){
            getMyProfile()
            .then(res=>{
                if(res?.data){
                    localStorage.setItem("user_",JSON.stringify(res?.data))
                }
                dispatch(hideLoader());
                navigate("/dashboard/projects");
            })
            .catch(err=>{ dispatch(hideLoader()); console.log(err)})

        }
    },[isLoggedIn]);
    
    const [authState, setAuthState] = useState({
        email:{
            value:"",
            error:"",
            touch:false
        },
        password:{
            value:"",
            error:"",
            touch:false
        }
    });

    const handleChange = (e)=>{

        setAuthState(state=>{
            const newState = JSON.parse(JSON.stringify(state));

            //validations
            if(e.target.name=="email"&&!isEmail(e.target.value)){
                newState.email.error="Please enter valid email"
            }
            else if(e.target.name=="email"&&!isLengthGreaterThan(e.target.value,0)){
                newState.email.error="Email is required"
            }
            else if(e.target.name=="email"){
                newState.email.error="";
            }

            if(e.target.name=="password"&&!isLengthGreaterThan(e.target.value,0)){
                newState.password.error="Password is required"
            }
            else if(e.target.name=="password"){
                newState.password.error=""
            }

            newState[e.target.name].value = e.target.value;
            newState[e.target.name].touch = true;

            return newState;

        })
        
    }

    const onSubmit =async ()=>{
        dispatch(showLoader("Authentication in process, please wait!"));
        if(authState.email.error!=""||authState.password.error!=""||authState.password.value==""||authState.email.value==""){
            toast.error("Invalid inputs");
            return;
        }
        dispatch(loginAsyncThunk(authState))
        .unwrap()
        .then(res=>dispatch(hideLoader()))
        .catch(res=>{toast.error("Invalid credentials!"); dispatch(hideLoader())});
    }

    return <div className={styles["container"]}>
            <Form>
                <div className={styles["wrapper"]}>
                    <H1>Login</H1>
                    <div className={styles["input"]}>
                        <Label>Email Address</Label>
                        <Input 
                        type="text" 
                        placeholder="user@gmail.com"
                        name="email"
                        onChange={handleChange}
                        value={authState.email.value}
                        error={authState.email.error}
                        />
                    </div>
                    <div className={styles["input"]}>
                        <Label>Password</Label>
                        <Input 
                        type="password" 
                        placeholder="********"
                        name="password"
                        onChange={handleChange}
                        value={authState.password.value}
                        error={authState.password.error}
                        />
                    </div>
                    <div className={styles["cta"]}>
                        <SubmitBtn text="Login"  onClick={onSubmit}/>
                    </div>
                </div>
            </Form>
    </div>
}

export default Login;