export const fetchAllProjects = async (__,thunkApi)=>{
    try{
        const {user} = thunkApi.extra.apiService;

        let query = "?";
        for(let key in __){
            query+=`${key}=${__[key]}&`;
        }
        const projects = await user.get(`/project${query}`);

        return projects;
    }
    catch(err){
        return Promise.reject(err);
    }
}