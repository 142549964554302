import { BsArrowLeftCircle, BsFilterLeft, BsSearch, BsXCircle } from "react-icons/bs";
import Input from "../Input";
import styles from "./index.module.css";
import MultiSelectDropDown from "../MultiSelectDropdown";
import NameDisplay from "../NameDisplay";
import H2 from "../../typography/H2";
import SubmitBtn from "../SubmitBtn";
import H5 from "../../typography/H5";
import OutlineBtn from "../OutlineBtn";
import StatsBox from "../StatsBox";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAllDealsAsyncThunk, setDeals, updateDealByIdThunk, updateDealMortgageByIdThunk, updateDealRealizedByIdThunk } from "../../../store/slices/deal/deal.slice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import { deleteDealById, saveSequence, updateSequence } from "../../../utils/calls.utils";
import {showLoader, hideLoader} from "../../../store/slices/loader/loader.slice";
import { clearStorage } from "../../../utils/global/global";
import Label from "../Label";
import { fetchAllAgentsAsyncThunk } from "../../../store/slices/agent/agent.slice";
import { fetchAllProjectsAsyncThunk } from "../../../store/slices/project/project.slice";
import { toast } from "react-toastify";
import Checkbox from "../Checkbox";
import { getAccessToken } from "../../../utils/global/auth.global";

const returnColor = (closing_date,mortgage,status)=>{
    if(!closing_date||typeof mortgage !== "boolean") return {};

    const today = new Date();
    let Days120 = new Date(closing_date);
    Days120.setDate(Days120.getDate()-120);
    let Days60 = new Date(closing_date);
    Days60.setDate(Days60.getDate()-60);

    if(status=="closed") return {backgroundColor:"rgb(200,230,255)"}
    else if(mortgage) return {backgroundColor:"rgb(200,255,200)"};
    else if(today<Days120) return {backgroundColor:""};
    else if(today<Days60) return {backgroundColor:"rgba(255,255,0)"};
    else return {backgroundColor:"rgb(255,200,200)"}
}
const MobileCard = ({deal,handleDelete,statusOptions,user,index})=>{
    const dispatch = useDispatch();
    const [selected,setStatusSelected] = useState(null);
    const [mortgage,setMortgage] = useState(false);
    const [realized,setRealized] = useState(false);

    const onSelect = (el)=>{
        setStatusSelected(el);
        dispatch(updateDealByIdThunk({id:deal?._id,selected:el?.value}));
    }

    const navigate = useNavigate();

    const handleUpdate = (e)=>{
        localStorage.setItem("is_deal_add",false);
        localStorage.setItem("deal_",JSON.stringify(deal));

        navigate("/deals/form");
    }
    const handleView = (e)=>{
        localStorage.setItem("deal_",JSON.stringify(deal));

        navigate("/deals/view");
    }
    const onChange = (name,check)=>{
        setMortgage(check);
        dispatch(updateDealMortgageByIdThunk({id:deal?._id,mortgage:check}))
        .unwrap()
        .then(res=>{
            toast.success("Deal updated succesfully")
        })
        .catch(err=>{
            console.log(err);
            toast.error("Deal updation failed");
        })
    }

    const onRealizedChange = (name,check)=>{
        setRealized(check);
        dispatch(updateDealRealizedByIdThunk({id:deal?._id,realized:check}))
        .unwrap()
        .then(res=>{
            toast.success("Deal updated succesfully")
        })
        .catch(err=>{
            console.log(err);
            toast.error("Deal updation failed");
        })
    }
    
    useEffect(()=>{
        setStatusSelected({
            value:deal?.status
        })
        setMortgage(deal?.mortgage&&true);
        setRealized(deal?.realized&&true);
    },[deal?.status,deal?.mortgage,deal?.realized]);
  
    return <div className={styles["card"]} style={returnColor(deal?.closing_date,(mortgage||false),deal?.status)}>
        <div>
            {user?.role=="admin" &&<div className={styles["cta"]} onClick={(e)=>handleDelete({name:`${deal?.project?.name} ${deal?.unit_no}`,_id:deal?._id})}><BsXCircle/></div>}
        </div>
        <div>
            <div>
                <H5>Job Number</H5>
                <div>{deal?.job_number?deal?.job_number:"-"}</div>
            </div>
            <div>
                <H5>Client Name</H5>
                <div>{deal?.purchasers && (deal?.purchasers[0]?.first_name+" "+deal?.purchasers[0]?.last_name)}</div>
            </div>
        </div>
        <div>
            <div>
                <H5>Amount</H5>
                <div>{deal?.sale_price}</div>
            </div>
            <div>
                <H5></H5>
                <div style={{width:"80%"}}><SingleSelectDropdown onChange={onSelect} selected={selected} title="status" options={statusOptions}/></div>
            </div>
        </div>
        <div>
            <div>
                <H5>Mortgage</H5>
                <div>
                    <Checkbox name="check" onChange={onChange} size={20} defaultVal={mortgage}/>
                </div>
            </div>
            <div>
                <H5>Realized</H5>
                <div>
                    <Checkbox name="check" onChange={onRealizedChange} size={20} defaultVal={realized}/>
                </div>
            </div>
        </div>
        <div>
            <div>
                <div className={styles["cta"]} onClick={handleView}>View</div>
            </div>
            <div>
                <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
            </div>
        </div>
    </div>;
}

const DesktopCard = ({deal,handleDragOver,handleDrop,handleDelete,statusOptions,user, handleDragStart, handleDragEnd,index }) =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selected,setStatusSelected] = useState(null);
    const [mortgage,setMortgage] = useState(false);
    const [realized,setRealized] = useState(false);

    const onSelect = (el)=>{
        
        setStatusSelected(el);
        dispatch(updateDealByIdThunk({id:deal?._id,selected:el?.value}))
        .unwrap()
        .then(res=>{
            toast.success("Deal updated succesfully")
        })
        .catch(err=>{
            console.log(err);
            toast.error("Deal updation failed");
        })
    }

    const onChange = (name,check)=>{
        setMortgage(check);
        dispatch(updateDealMortgageByIdThunk({id:deal?._id,mortgage:check}))
        .unwrap()
        .then(res=>{
            toast.success("Deal updated succesfully")
        })
        .catch(err=>{
            console.log(err);
            toast.error("Deal updation failed");
        })
    }

    const onRealizedChange = (name,check)=>{
        setRealized(check);
        dispatch(updateDealRealizedByIdThunk({id:deal?._id,realized:check}))
        .unwrap()
        .then(res=>{
            toast.success("Deal updated succesfully")
        })
        .catch(err=>{
            console.log(err);
            toast.error("Deal updation failed");
        })
    }

    const handleUpdate = (e)=>{
        localStorage.setItem("is_deal_add",false);
        localStorage.setItem("deal_",JSON.stringify(deal));

        navigate("/deals/form");
    }
    const handleView = (e)=>{
        localStorage.setItem("deal_",JSON.stringify(deal));

        navigate("/deals/view");
    }

    useEffect(()=>{
        setStatusSelected({
            value:deal?.status
        })
        setMortgage(deal?.mortgage&&true);
        setRealized(deal?.realized&&true);
    },[deal?.status,deal?.mortgage,deal?.realized]);

    return <div draggable="true"
    onDragStart={(e) =>  
        handleDragStart(e, deal)} 
    onDragEnd={handleDragEnd} 
    onDragOver={handleDragOver} 
    onDrop={(e) => handleDrop(e, deal)}  className={styles["row"]} style={returnColor(deal?.closing_date,(mortgage||false),deal?.status)}>
        <div>{deal?.job_number?deal?.job_number:"-"}</div>
        <div>{deal?.unit_no?deal?.unit_no:"-"}</div>
        <div>{deal?.project?.addressLine?deal?.project?.addressLine:"-"}</div>
        <div>{deal?.purchasers && (deal?.purchasers[0]?.first_name+" "+deal?.purchasers[0]?.last_name)}</div>
        <div>{isNaN(parseFloat(deal?.sale_price)?.toFixed(2))?"-":parseFloat(deal?.sale_price)?.toFixed(2)}</div>
        <div><SingleSelectDropdown style={{fontSize:"1.2rem"}} stylem={{fontSize:"1.2rem"}} onChange={onSelect} selected={selected} title={"status"} options={statusOptions}/></div>
        <div><Checkbox name="check" onChange={onChange} size={20} defaultVal={mortgage}/></div>
        <div><Checkbox name="check" onChange={onRealizedChange} size={20} defaultVal={realized}/></div>
        <div className={styles["cta"]} onClick={handleView}>View</div>
        <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
        {user?.role=="admin" &&<div className={styles["cta"]} onClick={()=>handleDelete({name:`${deal?.project?.name} ${deal?.unit_no}`,_id:deal?._id})}><BsXCircle/></div>}
    </div>
}

const Deals = (props)=>{
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const deals = useSelector(state=>state.deal.deals);
    const meta = useSelector(state=>state.deal.meta);
    const agents = useSelector(state=>state?.agent?.agents);
    const projects = useSelector(state=>state?.project);

    const [statusSelected,setStatusSelected] = useState({});
    const [excludeSelected,setExcludeSelected] = useState({});
    const [agentSelected,setAgentSelected] = useState({});
    const [projectSelected,setProjectSelected] = useState({});
    const [draggedIndex, setDraggedIndex] = useState(null);
    
    const [load,setLoad] = useState(false);
    const [modal,setModal] = useState({show:false,data:null});
    const [user,setUser] = useState({});

    const statusOptions = [
        {value:'open'},
        {value:'initialized'},
        {value:'firmed'},
        {value:'dft'},
        {value:'closed'},
    ]

    // const handleDragStart = (e, index) => {
    //     if(!allowDrag) return;

    //     e.dataTransfer.setData('text/plain', ''); // required for Firefox
    //     setDraggedIndex(index);

    //     toast.success(`Drag start ${index}`);
    // };
    
    // const handleDragEnter = (e, index) => {
    //     e.preventDefault();

    //     if(!allowDrag||draggedIndex==index) return;

    //     const updatedCards = Array.from(deals);
    //     const draggedCard = updatedCards[draggedIndex];
    
    //     updatedCards.splice(draggedIndex, 1);
    //     updatedCards.splice(index, 0, draggedCard);
    
    //     dispatch(setDeals(updatedCards));
    //     setDraggedIndex(index);

    //     toast.success(`Drag enter ${index}`);

    // };
    
    // const handleDragEnd = (e,index) => {
    //     if(!allowDrag) return;

    //     setDraggedIndex(null);
    //     toast.success(`Drag end ${index}`);

    // };

    const handleDragStart = (e, item) => { 
        if(!allowDrag) return;
        setDraggedIndex(item); 
        e.dataTransfer.setData('text/plain', ''); 
    }; 
  
    const handleDragEnd = () => { 
        if(!allowDrag) return;
        setDraggedIndex(null);
    }; 
  
    const handleDragOver = (e) => { 
        e.preventDefault(); 
    }; 
  
    const handleDrop = (e, targetItem) => { 
        if(!allowDrag) return;
        if (!draggedIndex) return; 
  
        const currentIndex = deals.indexOf(draggedIndex); 
        const targetIndex = deals.indexOf(targetItem); 
  
        if (currentIndex !== -1 && targetIndex !== -1) { 
            const cloneDeals = Array.from(deals);
            cloneDeals.splice(currentIndex, 1); 
            cloneDeals.splice(targetIndex, 0, draggedIndex); 
            dispatch(setDeals(cloneDeals));

        } 
    };

    const [statusOptionsArray,setStatusOptionsArray] = useState(['open','initialized','firmed','dft','closed']);
    const [agentOptions,setAgentOptions] = useState([]);
    const [projectOptions,setProjectOptions] = useState([]);
    const [afterDate,setAfterDate] = useState("");
    const [beforeDate,setBeforeDate] = useState("");
    const [closingBefore,setClosingBefore] = useState("");
    const [filterExpand,setFilterExpand] = useState(false);
    const [allowDrag,setAllowDrag] = useState(true);

    const onAddDealHandler = (e)=>{
        localStorage.setItem("is_deal_add",true);
        navigate("/deals/form");
    }

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        const projectId = queryParams.get("projectId");

        if(projectId&&projects?.length > 0){
            let projectName = projects?.find(el=>el?._id==projectId)?.name;
            setProjectSelected(state=>{
                const newState = {};
                for(let i=0;i<projects?.length;i++){
                    newState[projects[i]?.name] = projects[i]?.name==projectName?true:false;
                }
                return newState;
            });
        }

        const agentId = queryParams.get("agentId");

        if(agentId&&agents?.length > 0){
            let agentName = agents?.find(el=>el?._id==agentId)?.name;
            setAgentSelected(state=>{
                const newState = {};
                for(let i=0;i<agents?.length;i++){
                    newState[agents[i]?.name] = agents[i]?.name==agentName?true:false;
                }
                return newState;
            });
        }
    },[agents,projects,Object.keys(agentSelected)?.length,Object.keys(projectSelected)?.length]);

    useEffect(()=>{
        setAgentOptions(agents?.map(agent=>agent?.name));
    },[agents?.length]);

    useEffect(()=>{
        setProjectOptions(projects?.map(project=>project?.name));
    },[projects?.length]);

    useEffect(()=>{
        let user;
        
        if(localStorage.getItem("user_")) user = JSON.parse(localStorage.getItem("user_"));

        if(user?.role=="admin") dispatch(fetchAllAgentsAsyncThunk());
        dispatch(fetchAllProjectsAsyncThunk());
        setUser(user);
    },[])

    const saveSequenceHandler =async ()=>{
        try{
            const sequence = deals?.map(deal=>deal?._id);

            await updateSequence({sequence});

            toast.success("Sequence saved succesfully!");
        }catch(err){
            toast.error("Unable to save sequence, try again!")
        }
    }

    useEffect(()=>{

        clearStorage();

        let status = "";
        for(let statuses in statusSelected){
            if(statusSelected[statuses]) status+=`${statuses},`;
        }

        let excludeStatus = "";
        for(let statuses in excludeSelected){
            if(excludeSelected[statuses]) excludeStatus+=`${statuses},`;
        }

        let agent = "";
        for(let agentName in agentSelected){
            if(agentSelected[agentName]){
                 let agentId = agents.find(el=>el?.name==agentName)?._id;
                 agent+=`${agentId},`;
            }
        }

        let project = "";
        for(let projectName in projectSelected){
            if(projectSelected[projectName]){
                 let projectId = projects.find(el=>el?.name==projectName)?._id;
                 project+=`${projectId},`;
            }
        }

        let q = {};

        let allowD = true;
        
        if(status?.length>0||excludeStatus.length>0||agent?.length>0||project?.length>0||afterDate||beforeDate||closingBefore) allowD = false;
        
        setAllowDrag(allowD);

        q.include_status = status?.length>0?status?.slice(0,status?.length - 1):status;
        q.exclude_status = excludeStatus.length>0?excludeStatus?.slice(0,excludeStatus?.length - 1):excludeStatus;
        if(agent?.length>0) q.agents = agent?.slice(0,agent?.length - 1);
        if(project?.length>0) q.projects = project?.slice(0,project?.length - 1);
        if(afterDate) q.after_date = afterDate;
        if(beforeDate) q.before_date = beforeDate;
        if(closingBefore) q.closing_before = closingBefore;
        q.showSequence = allowD;

        dispatch(fetchAllDealsAsyncThunk({...q}));
    },[statusSelected,excludeSelected,agentSelected,projectSelected,afterDate,beforeDate,closingBefore,load]);

    const handleDelete = (deal)=>{
        setModal({show:true,data:deal});
    }

    const onAction =async (e)=>{
        try{
            dispatch(showLoader(`Deleting ${modal?.data?.name}, Please wait..`));
            await deleteDealById(modal?.data?._id);
            toast.success("Deleting deal succesfully");
        }catch(err){
            console.log(err);
            toast.error("Deleting deal failed");
        }finally{
            setLoad(state=>!state);
            dispatch(hideLoader());
            setModal({show:false,data:null});
        }
    }

    const onClose =  (e)=>{
        setModal({show:false,data:null});
    }

    const closingUnder120Handler = (bool)=>{
        if(!bool)
        {
            setClosingBefore(new Date(Date.now()+(120 * 24 * 60 * 60 * 1000)));
            setLoad(state=>!state);
        }
        else{
            setClosingBefore("");
            setLoad(state=>!state);
        }
    }

    const createThisMonthHandler = (bool)=>{
        if(!bool)
        {
            // Get the current date
            const today = new Date();

            // Get the first day of the month
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

            // Get the last day of the current month
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            setBeforeDate(lastDayOfMonth)
            setAfterDate(firstDayOfMonth);
            setLoad(state=>!state);
        }
        else{
            setBeforeDate("")
            setAfterDate("");
            setLoad(state=>!state);
        }
    }

    const toggleFilterExpand = ()=>{
        setFilterExpand(state=>!state);
    }

    const onStatusSelectChange = (obj)=>{
        setStatusSelected(obj);
    }

    const onExcludeSelectChange = (obj)=>{
        setExcludeSelected(obj);
    }

    const onAgentSelectChange = (obj)=>{
        setAgentSelected(obj);
    }

    const onProjectSelectChange = (obj)=>{
        setProjectSelected(obj);
    }

    const onDateChange = (e)=>{
        if(e?.target?.name=="after_date") setAfterDate(e?.target?.value);
        if(e?.target?.name=="before_date") setBeforeDate(e?.target?.value);
        if(e?.target?.name=="closing_before") setClosingBefore(e?.target?.value);
    }

    return <>
    {
        filterExpand && <div className={styles["filter_box"]}>
            <div className={styles["filter_wrapper"]}>
                <div className={styles["filter_back"]} >
                    <BsArrowLeftCircle onClick={toggleFilterExpand}/>
                </div>
                <div className={styles["filter_row"]}>
                    <div>
                        <MultiSelectDropDown title={"Exclude Status"} options={statusOptionsArray} onChange={onExcludeSelectChange}/>
                    </div>
                </div>
                {user?.role =="admin" && <div className={styles["filter_row"]}>
                    <div>
                        <MultiSelectDropDown title={"Select Agents"} options={agentOptions} onChange={onAgentSelectChange}/>
                    </div>
                </div>}
                <div className={styles["filter_row"]}>
                    <div>
                        <MultiSelectDropDown title={"Select Projects"} options={projectOptions} onChange={onProjectSelectChange}/>
                    </div>
                </div>
                <div className={styles["filter_row"]}>
                    <div className={styles["input_col"]} style={{marginBottom:"calc(2 * var(--basey))"}}>
                        <Label>After Date</Label>
                        <Input type="date" name="after_date" onChange={onDateChange} value={afterDate}/>
                    </div>
                </div>
                <div className={styles["filter_row"]}>
                    <div className={styles["input_col"]} style={{marginBottom:"calc(2 * var(--basey))"}}>
                        <Label>Before Date</Label>
                        <Input type="date" name="before_date" onChange={onDateChange} value={beforeDate}/>
                    </div>
                </div>
                <div className={styles["filter_row"]}>
                    <div className={styles["input_col"]} style={{marginBottom:"calc(2 * var(--basey))"}}>
                        <Label>Closing Before</Label>
                        <Input type="date" name="closing_before" onChange={onDateChange} value={closingBefore}/>
                    </div>
                </div>
            </div>
        </div>
    }
    {modal.show && <Modal 
    onAction={onAction}
    onClose={onClose}
    action={"delete"}
    message={`Are you sure you want to delete ${modal?.data?.name}`}
    />}
    <div className={styles["container"]}>
        <div className={styles["filters"]}>
            <div className={styles["btns"]}>
                <div>
                    <div>
                        <div>
                            <OutlineBtn text="Closing under 120 days" onClick={closingUnder120Handler}/>
                        </div>
                        <div>
                            <OutlineBtn text="Created this month" onClick={createThisMonthHandler}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <MultiSelectDropDown title={"Status"} options={statusOptionsArray} onChange={onStatusSelectChange}/>
                        </div>
                        <div>
                            <div className={styles["advance_filters"]} onClick={toggleFilterExpand}>
                                <BsFilterLeft/>
                                Advance Filters
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <NameDisplay name={`${user?.firstName} ${user?.lastName}`}/>
                </div>
            </div>
            {user?.role=="admin" && <div className={styles["stats"]}>
                <div>
                    <div>
                        <StatsBox heading="TOTAL SALES" stat={(parseFloat(meta?.total_sales)?.toFixed(2)) || '-'}/>
                    </div>
                    <div>
                        <StatsBox heading="MORTGAGED DEALS" stat={meta?.mortgage_count || '-'}/>
                    </div>
                </div>
                <div>
                    <div>
                        <StatsBox heading="COMMISSIONS EARNED" stat={(parseFloat(meta?.commission_earned)?.toFixed(2)) || '-'}/>
                    </div>
                    <div>
                        <StatsBox heading="DEAL COUNT" stat={deals?.length || '-'}/>
                    </div>
                </div>
            </div>}
        </div>
        <div className={styles["data"]}>
            <div className={styles["head"]}>
                <H2>Deals</H2>
                <SubmitBtn text="ADD DEAL" onClick={onAddDealHandler}/>
            </div>
            {deals?.length <= 0 && <div style={{width:"100%", textAlign:"center"}}><H5>No Deals Found, Create Your First Deal Using Add Deal Button</H5></div>}
            {deals?.length > 0 && <div className={styles["content"]}>
                {window.innerWidth<= 700 && (<>
                    {deals?.map((el,idx)=><MobileCard handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          handleDragEnd={handleDragEnd} index={idx} key={idx} user={user} statusOptions={statusOptions} selected={statusSelected} deal={el} handleDelete={handleDelete}/>)}
                </>)}
                {window.innerWidth> 700 && (<>
                    <div className={styles["top"]}>
                        <div><H5>Job Number</H5></div>
                        <div><H5>Unit No</H5></div>
                        <div><H5>Project Address</H5></div>
                        <div><H5>Client Name</H5></div>
                        <div><H5>Amount</H5></div>
                        <div><H5>Status</H5></div>
                        <div><H5>Mortgage</H5></div>
                        <div><H5>Realized</H5></div>
                    </div>
                    {deals?.map((el,idx)=><DesktopCard handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          handleDragEnd={handleDragEnd} index={idx} key={idx}  user={user} statusOptions={statusOptions} selected={statusSelected} deal={el} handleDelete={handleDelete}/>)}
                </>)}
            </div>}
        </div>
        {user.role=="admin" && <a href={process.env.REACT_APP_API_DOMAIN+"/deal/exports?jwt="+getAccessToken()} target="_blank" className={styles["export"]}>Export</a>}
        {user.role=="admin" && allowDrag && <div  onClick={saveSequenceHandler} className={styles["save"]}>Save</div>}
    </div>
    </>
}

export default Deals;