import { useNavigate } from "react-router-dom";
import Form from "../../elements/Form1";
import Input from "../../elements/Input";
import Label from "../../elements/Label";
import SubmitBtn from "../../elements/SubmitBtn";
import H1 from "../../typography/H1";
import H2 from "../../typography/H2";
import H5 from "../../typography/H5";
import HR from "../../typography/HR";
import P from "../../typography/P";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { isEqualTo, isLengthGreaterThan } from "../../../utils/validators";
import { postProject, updateProject } from "../../../utils/calls.utils";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
//
const BuilderForm = (props)=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [builder,setBuilder]=useState({
        name:{
            value:"",
            error:""
        },
        address:{
            value:"",
            error:""
        },
        city:{
            value:"",
            error:""
        },
        postalCode:{
            value:"",
            error:""
        },
    });

    useEffect(()=>{
        const builder = JSON.parse(localStorage.getItem('builder'));
      
        if(builder)
        setBuilder(builder);
        else if(localStorage.getItem('is_project_add')!=="true")
        {
            let project = JSON.parse(localStorage.getItem("project_"));

            if(!project) return;

            project = {
                name:{value:project?.builder?.name,error:""},
                address:{value:project?.builder?.addressLine,error:""},
                city:{value:project?.builder?.city,error:""},
                postalCode:{value:project?.builder?.postalCode,error:""},
            }

            setBuilder(project);
        }
    },[]);

    const onChange = (e)=>{
        setBuilder(state=>{
            let newState = JSON.parse(JSON.stringify(state));

            //validations
            if(e?.target?.name=="name"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Builder name is required!";
            }
            else if(e?.target?.name=="name"){
                newState[e?.target?.name].error="";
            }

            if(e?.target?.name=="address"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Builder address is required!";
            }
            else if(e?.target?.name=="address"){
                newState[e?.target?.name].error="";
            }

            if(e?.target?.name=="city"&&!isLengthGreaterThan(e?.target?.value, 0)){
                newState[e?.target?.name].error="Builder city is required!";
            }
            else if(e?.target?.name=="city"){
                newState[e?.target?.name].error="";
            }
            
            if(e?.target?.name=="postalCode"&&!isEqualTo(e?.target?.value, 6)){
                newState[e?.target?.name].error="Postal code should be 6 digits!";
            }
            else if(e?.target?.name=="postalCode"){
                newState[e?.target?.name].error="";
            }

            newState[e?.target?.name].value = e?.target?.value;

            return newState;
        })
    }

    const onSubmit = async (e)=>{
        try{
            if(builder?.name?.error!=""||builder?.address?.error!=""||builder?.city?.error!=""||builder?.postalCode?.error!=""||builder?.name?.value==""||builder?.address?.value==""||builder?.city?.value==""||builder?.postalCode?.value==""){
                return toast.error("Submission invalid!");
            }

            if(localStorage.getItem('is_project_add')==="true")
                dispatch(showLoader("Creating new project, Please wait ..."));
            else
                dispatch(showLoader("Updating project, Please wait ..."));

            
            const project = JSON.parse(localStorage.getItem("project"));

            let res;
            if(localStorage.getItem('is_project_add')==="true")
                res = await postProject(builder,project);
            else{
                let id = JSON.parse(localStorage.getItem('project_'))?._id;

                res = await updateProject(builder,project,id);
            }
            
            localStorage.removeItem("project");
            localStorage.removeItem("builder");

            dispatch(hideLoader())
            navigate("/dashboard/projects");
        }catch(err){
            toast.error('Submission failed! Try again');
            console.log(err);
        }
    }

    const onBack = ()=>{
        localStorage.setItem("builder",JSON.stringify(builder));
        navigate("/projects");
    }

    const onDiscard = ()=>{
        localStorage.removeItem("builder");
        localStorage.removeItem("project");

        navigate("/dashboard/projects");
    }
    return <div className={styles["container"]}>
            <Form>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>{localStorage.getItem('is_project_add')==="true"?"Add":"Update"} Builder</H1>
                    <P style={{borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}>Step 2 of 2, {localStorage.getItem('is_project_add')==="true"?"new project will be created on submit":"project will be updated on submit"} </P>
                    <div className={styles["input"]}>
                        <Label>Builder Name</Label>
                        <Input type="text" placeholder="Lodha Group" value={builder?.name?.value} error={builder?.name?.error} onChange={onChange} name="name"/>
                    </div>
                    <div className={styles["input"]}>
                        <Label>Address</Label>
                        <Input type="text" placeholder="Main Street, Southeast" value={builder?.address?.value} error={builder?.address?.error} onChange={onChange} name="address"/>
                    </div>
                    <div className={styles["split"]}>
                        <div className={styles["input"]}>
                            <Label>City</Label>
                            <Input type="text" placeholder="Calgary" value={builder?.city?.value} error={builder?.city?.error} onChange={onChange} name="city"/>
                        </div>
                        <div className={styles["input"]}>
                            <Label>Postal Code</Label>
                            <Input type="text" placeholder="20577" value={builder?.postalCode?.value} error={builder?.postalCode?.error} onChange={onChange} name="postalCode"/>
                        </div>
                    </div>
                    <div className={styles["cta"]}>
                        <SubmitBtn text="Submit" onClick={onSubmit}/>
                        <H5 style={{color:"var(--color_dark_1)",cursor:"pointer"}} onClick={onDiscard}>Discard</H5>
                        <H5 style={{color:"var(--color_dark_1)",cursor:"pointer"}} onClick={onBack}>Back</H5>
                    </div>
                </div>
            </Form>
    </div>
}

export default BuilderForm;