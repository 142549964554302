import { BsDownload, BsLink45Deg, BsXCircle } from "react-icons/bs";
import H5 from "../../typography/H5";
import P from "../../typography/P";
import Input from "../Input";
import Label from "../Label";
import SingleSelectDropdownv2 from "../SingleSelectDropdownv2";
import SubmitBtn from "../SubmitBtn";
import styles from "./index.module.css";
import {useDispatch} from "react-redux";
import React, { useEffect, useRef, useState } from 'react'
import { isDateMMDDYYYY } from "../../../utils/validators";
import { fetchDealById, postDeposit, updateDeal, updateDeposit } from "../../../utils/calls.utils";
import {hideLoader, showLoader} from "../../../store/slices/loader/loader.slice.js";
import {useNavigate} from "react-router-dom";
import { defaultAxios } from "../../../utils/axios/default.axios";
import { userAxios } from "../../../utils/axios/user.axios";
import { redirectUsingFileName } from "../../../utils/download.utils";
import { toast } from "react-toastify";
import Checkbox from "../Checkbox/index.js";

const DepositCard = ({isFirst,onDelete,idx,data,onChange})=>{

    const fileRef = useRef();

    const onDeleteHandler =()=>{
        onDelete(idx);
    }

    const onDepositChange = (e)=>onChange(e,idx);

    const onDepositFileChange = (e)=>onChange(e,idx,true);

    const onProofClick = (e)=>fileRef.current.click();
    
    const onDownload = async (e)=>{
        if(typeof data?.old_proof=="string")
        await redirectUsingFileName(data?.old_proof);
    }
    return <div className={styles["card"]}>
        <div>
            <div>
                <H5>Deposit {idx+1}</H5>
                <div onClick={onDeleteHandler}>
                    {!isFirst && <BsXCircle/>}
                </div>
            </div>
            <div className={styles["input"]}>
                <Label>Deposit Amount</Label>
                <Input 
                type="number" 
                placeholder="1,00,000"
                name="amount"
                value={data?.amount?.value}
                error={data?.amount?.error}
                onChange={onDepositChange}
                />
            </div>   
            {!isFirst && <div className={styles["input"]}>
                <Label>Scheduled Date</Label>
                <Input
                type="date" 
                placeholder="MM/DD/YYYY"
                name="scheduled_date"
                value={data?.scheduled_date?.value}
                error={data?.scheduled_date?.error}
                onChange={onDepositChange}
                />
            </div>}         
            <input type="file" onChange={onDepositFileChange} style={{display:"none",visibility:"hidden"}} ref={fileRef}/>   
            <div className={styles["documentBox"]}>
                <div>
                    <H5>Proof Of Deposit</H5>
                </div>
                <div>
                    {data?.old_proof && <BsDownload onClick={onDownload}/>}
                </div>
                <div onClick={onProofClick}>
                    <BsLink45Deg/>
                </div>
            </div>
            <div className={styles["filename"]} style={{marginBottom:"calc(2 * var(--basey))"}}>{data?.proof?.name}</div>
        </div>
    </div>;
}
const OfferFinancials = ({onNext}) => {
  const [deposits,setDeposits] = useState([]);
  const [data,setData] = useState({});
  const [isClosing,setIsClosing] = useState(false);
  const [user,setUser] = useState({});

  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{

    let dealId = localStorage.getItem("deal_");

    if(!dealId) return toast.error("Please goto dashboard and come back!");

    dealId = JSON.parse(dealId)?._id;

    fetchDealById(dealId)
    .then(res=>{
        const data = res?.data;
   
        if(data?.deposits?.length==0)
        setDeposits(state=>{
            return [
                {
                    amount:{
                        value:"",
                        error:""
                    },
                    proof: null,
                    scheduled_date:{
                        value:"",
                        error:""
                    },
                    _id:null
                }
            ]
        });
        else{
           const deposits = [];

           for(let i=0;i<data?.deposits?.length;i++){
            let cur = data?.deposits[i];

             deposits.push({
                amount:{
                    value:cur?.amount || 0,
                    error:""
                },
                scheduled_date:{
                    value: cur?.scheduled_date?.split("T")[0] || null,
                    error:""
                },
                proof: null,
                _id: cur?._id,
                old_proof: cur?.proof || null
             })
           }

           setDeposits(deposits);
        }
    
        if(data?.init_commission) setIsClosing(true);
    
        setData(state=>{
            return {
                sale_price:{
                    value:data?.sale_price,
                    error:""
                },
                offer_acceptance_date:{
                    value:data?.offer_acceptance_date?.split("T")[0],
                    error:""
                },
                closing_date:{
                    value:data?.closing_date?.split("T")[0],
                    error:""
                },
                total_commission:{
                    value:data?.total_commission,
                    error:""
                },
                commission_tax_percentage:{
                    value:data?.commission_tax_percentage,
                    error:""
                },
                commission_tax_type:{
                    value:data?.commission_tax_type,
                    error:""
                },
                init_commission_date:{
                    value:data?.init_commission_date?.split("T")[0],
                    error:""
                },
                init_commission:{
                    value:data?.init_commission,
                    error:""
                },
                tax_percentage:{
                    value:data?.tax_percentage,
                    error:""
                },
                payout:{
                    value:parseFloat(data?.sale_price)+(parseFloat(data?.sale_price)*(parseFloat(data?.tax_percentage)/100)),
                    error:""
                },
                after_tax_commission:{
                    value:parseFloat(data?.total_commission)+(parseFloat(data?.total_commission)*(parseFloat(data?.commission_tax_percentage)/100)),
                    error:""
                }
            }
        })
    })
    .catch(err=>{
        console.log(err);
        toast.error("Fetching deal failed")
    })

    if(localStorage.getItem("user_")) setUser(JSON.parse(localStorage.getItem("user_")));
    
  },[]);

  const onAddDeposit = ()=>{
      const depositObj = {
        amount:{
            value:"",
            error:""
        },
        proof: null,
        scheduled_date:{
            value:"",
            error:""
        },
        _id:null
    };

    const newDeposits = [...deposits,depositObj];

    setDeposits(newDeposits);
  }

  const onRemoveDeposits = (idx)=>{
    if(!idx||idx<0||idx>=deposits?.length) return;
    
    const updatedDeposits = deposits.filter((_, i) => i !== idx);

    setDeposits(updatedDeposits);
  }

  const onChangeInDeposits = (e,idx,isFile)=>{
        setDeposits(state=>{
            const newState = JSON.parse(JSON.stringify(state[idx]));

            newState.proof = state.proof;

            let name = e?.target?.name;

            if(name=="amount"&&(parseFloat(e?.target?.value)<0)){
                newState[name].error = "Invalid amount!"
            }
            else if(name=="amount"){
                newState[name].error = ""
            }
            
            if(name=="scheduled_date"&&(!isDateMMDDYYYY(e?.target?.value,true,false))){
                newState[name].error = "Invalid Scheduled Date!"
            }
            else if(name=="scheduled_date"){
                newState[name].error = ""
            }

            if(!isFile)
            newState[name].value = e?.target?.value;
            else 
            newState["proof"] = e?.target.files[0];

            // Create a new array with the updated object at the specified index
            const updatedDeposits = [...deposits];
            updatedDeposits[idx] = newState;

            return updatedDeposits;
        })
    }
  const onChange = (e)=>{

    setData(state=>{
        const newState = JSON.parse(JSON.stringify(state));

        const name = e?.target?.name;

        if(name=="sale_price"&&parseInt(e?.target?.value)<0){
            newState[name].error="Invalid Sale Price!"
        }
        else if(name=="sale_price"){
            newState[name].error="";
            if(newState.sale_price.value!=="")
            newState.payout.value = parseFloat(e?.target?.value) + (parseFloat(e?.target?.value) * (parseFloat(data.tax_percentage.value)/100));
        }

        if(name=="tax_percentage"&&(parseFloat(e?.target?.value)<0||parseFloat(e?.target?.value)>100)){
            newState[name].error="Invalid Tax Percentage!"
        }
        else if(name=="tax_percentage"){
            newState[name].error="";
            if(newState.sale_price.value!=="")
            newState.payout.value = parseFloat(newState.sale_price.value) + (parseFloat(newState.sale_price.value) * (parseFloat(e?.target?.value)/100));
        }

        if(name=="commission_tax_percentage"&&(parseFloat(e?.target?.value)<0||parseFloat(e?.target?.value)>100)){
            newState[name].error="Invalid Tax Percentage!"
        }
        else if(name=="commission_tax_percentage"){
            newState[name].error="";
            if(newState.total_commission.value!=="")
            newState.after_tax_commission.value = parseFloat(newState.total_commission.value) + (parseFloat(newState.total_commission.value) * (parseFloat(e?.target?.value)/100));
        }

        if(name=="offer_acceptance_date"&&!isDateMMDDYYYY(e?.target?.value)){
            newState[name].error="Invalid Offer Acceptance Date!"
        }
        else if(name=="offer_acceptance_date"){
            newState[name].error="";
        }

        if(name=="closing_date"&&!isDateMMDDYYYY(e?.target?.value,true)){
            newState[name].error="Invalid Closing Date!"
        }
        else if(name=="closing_date"){
            newState[name].error="";
        }

        if(name=="total_commission"&&((parseFloat(e?.target?.value)<0)||(parseFloat(e?.target?.value)>data.payout.value))){
            newState[name].error="Invalid Commission!"
        }
        else if(name=="total_commission"){
            newState[name].error="";
            if(newState.total_commission.value!=="")
            newState.after_tax_commission.value = parseFloat(e?.target?.value) + (parseFloat(e?.target?.value) * (parseFloat(data.commission_tax_percentage.value)/100));
        }

        if(name=="init_commission"&&((parseFloat(e?.target?.value)<0)||(parseFloat(e?.target?.value)>data.payout.value))){
            newState[name].error="Invalid Commission!"
        }
        else if(name=="init_commission"){
            newState[name].error="";
        }

        if(name=="init_commission_date"&&!isDateMMDDYYYY(e?.target?.value,true)){
            newState[name].error="Invalid Commission Payment Date!"
        }
        else if(name=="init_commission_date"){
            newState[name].error="";
        }

        newState[name].value = e?.target?.value;

        return newState;
    })
  }

  const onSubmit = async (e)=>{
    try{
        if(
            data?.sale_price?.value==""
        ||  data?.sale_price?.error!=""
        ||  data?.tax_percentage?.value===""
        ||  data?.tax_percentage?.error!=""
        ||  data?.offer_acceptance_date?.value==""
        ||  data?.offer_acceptance_date?.error!=""
        ||  data?.closing_date?.value==""
        ||  data?.closing_date?.error!=""
        ){
            return toast.error("Invalid submission!");
        }

        for(let i=0;i<deposits?.length;i++){
            if(i==0&&(deposits[i]?.amount?.value==""||deposits[i]?.amount?.error!=""||!(deposits[i]?.proof||deposits[i]?.old_proof))) return toast.error("Invalid submission");
            else if(i!=0&&(deposits[i]?.amount?.value==""||deposits[i]?.amount?.error!=""||deposits[i]?.scheduled_date?.value==""||deposits[i]?.scheduled_date?.error!="")) return toast.error("Invalid submission");
        }

        dispatch(showLoader("Updating Financial Data! Please Wait!"));

        let dealId = localStorage.getItem("deal_");

        if(!dealId) return toast.error("Please goto dashboard and come back!");

        dealId = JSON.parse(dealId)?._id;

        const depositIds = [];

        for(let i=0;i<deposits?.length;i++){
            let res;
            if(!deposits[i]._id)
            res = await postDeposit(deposits[i],dealId);
            else
            res = await updateDeposit(deposits[i],dealId);

            depositIds.push(res?.data?._id);
        }

        const dealData = new FormData();

        dealData.append('sale_price',data?.sale_price?.value);
        dealData.append('tax_percentage',data?.tax_percentage?.value);
        dealData.append('offer_acceptance_date',data?.offer_acceptance_date?.value);
        dealData.append('closing_date',data?.closing_date?.value);
        if(data?.total_commission?.value)
        dealData.append('total_commission',data?.total_commission?.value);
        if(data?.commission_tax_percentage?.value)
        dealData.append('commission_tax_percentage',data?.commission_tax_percentage?.value);
        if(data?.commission_tax_type?.value)
        dealData.append('commission_tax_type',data?.commission_tax_type?.value);
        dealData.append('deposits',depositIds?.join(","));

        if(isClosing&&data?.init_commission_date?.value)
        dealData.append('init_commission_date',data?.init_commission_date?.value);

        if(isClosing&&data?.init_commission?.value)
        dealData.append('init_commission',data?.init_commission?.value);
        else
        dealData.append('init_commission',0);

        const res = await updateDeal(dealData,dealId);

        if(user?.role=="admin")
        onNext();
    }catch(err){
        console.log(err);
        toast.error("Submission failed! Try again");
    }
    finally{
        dispatch(hideLoader());
    }
  }

  const onCheckboxChange = ()=>{
    setIsClosing(state=>!state);
  }

  return (
    <div className={styles["form"]}>
                <div className={styles["input"]}>
                        <Label>Sale Price</Label>
                        <Input 
                        type="number" 
                        placeholder="10,00,000"
                        name="sale_price"
                        value={data?.sale_price?.value}
                        error={data?.sale_price?.error}
                        onChange={onChange}
                        />
                </div>
                <div className={styles["input"]}>
                        <Label>Tax Percentage</Label>
                        <Input 
                        min={0} 
                        max={100} 
                        type="number" 
                        placeholder="10.5"
                        name="tax_percentage"
                        value={data?.tax_percentage?.value+""}
                        error={data?.tax_percentage?.error}
                        onChange={onChange}
                        />
                </div>
                <div className={styles["input"]}>
                        <Label>Sale Price After Tax</Label>
                        <Input 
                        editable={false} 
                        type="number" 
                        placeholder="11,05,000"
                        name="payout"
                        value={data?.payout?.value}
                        error={data?.payout?.error}
                        onChange={onChange}
                        />
                </div>
                <div className={styles["input"]}>
                        <Label>Offer Acceptance Date</Label>
                        <Input 
                        type="date" 
                        name="offer_acceptance_date"
                        value={data?.offer_acceptance_date?.value}
                        error={data?.offer_acceptance_date?.error}
                        onChange={onChange}
                        />
                </div>
                <div className={styles["input"]}>
                        <Label>Closing Date</Label>
                        <Input 
                        type="date" 
                        name="closing_date"
                        value={data?.closing_date?.value}
                        error={data?.closing_date?.error}
                        onChange={onChange}
                        />
                </div>
                {user?.role=="admin" &&<><div className={styles["input"]}>
                        <Label>Total Commission</Label>
                        <Input 
                        type="number" 
                        placeholder="60,000"
                        name="total_commission"
                        value={data?.total_commission?.value}
                        error={data?.total_commission?.error}
                        onChange={onChange}
                        />
                </div>
                <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
                    <div style={{display:"flex"}}><Checkbox defaultVal={isClosing} size={20} onChange={onCheckboxChange} name={"check"}/> <span style={{marginLeft:"calc(1 * var(--basex))"}}>Is Commission paid before closing?</span></div>
                </div>
                {
                    isClosing && <>
                        <div className={styles["input"]}>
                            <Label>Pre-Commission Date</Label>
                            <Input 
                            type="date" 
                            name="init_commission_date"
                            value={data?.init_commission_date?.value}
                            error={data?.init_commission_date?.error}
                            onChange={onChange}
                            />
                        </div>
                        <div className={styles["input"]}>
                                <Label>Pre-Commission Amount</Label>
                                <Input 
                                type="number" 
                                placeholder="60,000"
                                name="init_commission"
                                value={data?.init_commission?.value}
                                error={data?.init_commission?.error}
                                onChange={onChange}
                                />
                        </div>
                    </>
                }</>}
                {user?.role=="admin" &&<>
                    <div className={styles["input"]}>
                            <Label>Tax Percentage On Commission</Label>
                            <Input 
                            type="number" 
                            placeholder="5"
                            name="commission_tax_percentage"
                            value={data?.commission_tax_percentage?.value}
                            error={data?.commission_tax_percentage?.error}
                            onChange={onChange}
                            />
                    </div>
                    <div className={styles["input"]}>
                            <Label>Total Commission After Tax</Label>
                            <Input 
                            type="number" 
                            placeholder="63,000"
                            name="after_tax_commission"
                            value={parseFloat(data?.after_tax_commission?.value)?.toFixed(2)+""}
                            error={data?.after_tax_commission?.error}
                            editable={false} 
                            onChange={onChange}
                            />
                    </div>
                    <div className={styles["input"]}>
                            <Label>Tax Type</Label>
                            <Input 
                            type="text" 
                            placeholder="HST"
                            name="commission_tax_type"
                            value={data?.commission_tax_type?.value}
                            error={data?.commission_tax_type?.error}
                            onChange={onChange}
                            />
                    </div>
                </>
                }
                <div>
                    <SubmitBtn onClick={onAddDeposit} text="Add Deposit" style={{marginBottom:"calc(3 * var(--basey))"}}/>
                </div>
                <div>
                {
                    deposits && deposits?.map((deposit,idx)=>{
                        return <DepositCard 
                        onDelete={onRemoveDeposits}
                        isFirst={idx==0}
                        idx={idx}
                        onChange={onChangeInDeposits}
                        data={deposit}
                        key={idx}
                        />
                    })
                }
                </div>
                <div>
                    <SubmitBtn text={user.role=="admin"?"Save And Next":"Save"} onClick={onSubmit}/>
                </div>
            </div>
  )
}

export default OfferFinancials