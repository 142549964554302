import { BsFilterLeft, BsSearch, BsXCircle } from "react-icons/bs";
import Input from "../Input";
import styles from "./index.module.css";
import MultiSelectDropDown from "../MultiSelectDropdown";
import NameDisplay from "../NameDisplay";
import H2 from "../../typography/H2";
import SubmitBtn from "../SubmitBtn";
import H5 from "../../typography/H5";
import OutlineBtn from "../OutlineBtn";
import StatsBox from "../StatsBox";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgentsAsyncThunk } from "../../../store/slices/agent/agent.slice";
import { deleteAgentById, fetchUniqueCompanies } from "../../../utils/calls.utils";
import { useNavigate } from "react-router-dom";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import Modal from "../Modal";
import { toast } from "react-toastify";

const MobileCard = ({agent,handleDelete})=>{
    const navigate = useNavigate();

    const handleUpdate = (e)=>{
        localStorage.setItem("is_agent_add",false);
        localStorage.setItem("agent_",JSON.stringify(agent));

        navigate("/agents");
    }

    const onDealView = ()=>{
        navigate(`/dashboard/deals?agentId=${agent?._id}`);
    }
    return <div className={styles["card"]}>
        <div>
            <div className={styles["cta"]} onClick={()=>handleDelete(agent)}><BsXCircle/></div>
        </div>
        <div>
            <div>
                <H5>Agent Name</H5>
                <div>{agent?.name}</div>
            </div>
            <div>
                <H5>Company Name</H5>
                <div>{agent?.company}</div>
            </div>
        </div>
        <div>
            <div>
                <H5>Total Commission</H5>
                <div>{agent?.dealInfo?.earned?parseFloat(agent?.dealInfo?.earned).toFixed(2):"-"}</div>
            </div>
            <div>
                <H5>Commission Realized</H5>
                <div>{agent?.dealInfo?.realized?parseFloat(agent?.dealInfo?.realized).toFixed(2):"-"}</div>
            </div>
        </div>
        <div>
            <div>
                <H5>Total Expense</H5>
                <div>{agent?.dealInfo?.expense?parseFloat(agent?.dealInfo?.expense).toFixed(2):"-"}</div>
            </div>
            <div>
                <H5>Total Loaned</H5>
                <div>{agent?.dealInfo?.loaned?parseFloat(agent?.dealInfo?.loaned).toFixed(2):"-"}</div>
            </div>
        </div>
        <div>
            <div>
                <H5>Deals</H5>
                <div>{agent?.dealInfo?.agentDeals?.length}</div>
            </div>
        </div>
        <div>
            <div>
                <div className={styles["cta"]} onClick={onDealView}>View Deals</div>
            </div>
            <div>
                <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
            </div>
        </div>
    </div>;
}

const DesktopCard = ({agent,handleDelete})=>{
    const navigate = useNavigate();

    const handleUpdate = (e)=>{
        localStorage.setItem("is_agent_add",false);
        localStorage.setItem("agent_",JSON.stringify(agent));

        navigate("/agents");
    }
    const onDealView = ()=>{
        navigate(`/dashboard/deals?agentId=${agent?._id}`);
    }
    return <div className={styles["row"]}>
        <div>{agent?.name}</div>
        <div>{agent?.company}</div>
        <div>{agent?.dealInfo?.earned?parseFloat(agent?.dealInfo?.earned).toFixed(2):"-"}</div>
        <div>{agent?.dealInfo?.expense?parseFloat(agent?.dealInfo?.expense).toFixed(2):"-"}</div>
        <div>{agent?.dealInfo?.agentDeals?.length}</div>
        <div className={styles["cta"]} onClick={onDealView}>View Deals</div>
        <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
        <div className={styles["cta"]} onClick={()=>handleDelete(agent)}><BsXCircle/></div>
    </div>
}
const Deals = (props)=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const agents = useSelector(state=>state.agent.agents);
    const meta = useSelector(state=>state.agent.meta);
    const [name,setName] = useState("");
    const [uniqueCities,setUniqueCities] = useState([]);
    const [citiesSelected,setCitiesSelected] = useState({});
    const [modal,setModal] = useState({show:false,data:null});
    const [load,setLoad] = useState(false);
    const [user,setUser] = useState({});

    const onNameChange =(e)=>{
        setName(e?.target?.value);
    }
    const onSelectedCityChange = (obj)=>{
        setCitiesSelected(obj);
    }

    useEffect(()=>{
        let city = "";
        for(let cities in citiesSelected){
            if(citiesSelected[cities]) city+=`${cities},`;
        }
        dispatch(fetchAllAgentsAsyncThunk({name,company:city}));
    },[name,citiesSelected,load]);

    useEffect(()=>{
        fetchUniqueCompanies()
        .then(res=>{
            setUniqueCities(res?.data);
        })
        .catch(err=>{
            toast.error("Fetching unique companies failed");
            console.log(err);
        })

        if(localStorage.getItem("user_")) setUser(JSON.parse(localStorage.getItem("user_")));
    },[])

    const onAddAgentHandler = (e)=>{
        localStorage.setItem("is_agent_add",true);
        navigate("/agents");
    }

    const handleDelete = (agent)=>{
        setModal({show:true,data:agent});
    }

    const onAction =async (e)=>{
        try{
            dispatch(showLoader(`Deleting ${modal?.data?.name}, Please wait..`));
            await deleteAgentById(modal?.data?._id);
            toast.success("Deleting agents succesfull");
        }catch(err){
            console.log(err);
            toast.error("Deleting agents failed");
        }finally{
            setLoad(state=>!state);
            dispatch(hideLoader());
            setModal({show:false,data:null});
        }
    }

    const onClose =  (e)=>{
        setModal({show:false,data:null});
    }
    
    return <>
    {modal.show && <Modal 
    onAction={onAction}
    onClose={onClose}
    action={"delete"}
    message={`Are you sure you want to delete ${modal?.data?.name}`}
    />}
    <div className={styles["container"]}>
        <div className={styles["filters"]}>
            <div className={styles["btns"]}>
                <div>
                    <div className={styles["input"]}>
                        <Input type="text" placeholder="Search for agent name" onChange={onNameChange} value={name}/>
                        {/* <div className={styles["search"]}><BsSearch/></div> */}
                    </div>
                    
                </div>
                <div>
                    <div>
                        <MultiSelectDropDown title={"Company"} options={uniqueCities} onChange={onSelectedCityChange}/>
                    </div>
                    <NameDisplay name={`${user?.firstName} ${user?.lastName}`}/>
                </div>
            </div>
            {user?.role=="admin" && <div className={styles["stats"]}>
                <div>
                    <div>
                        <StatsBox heading="TOTAL AGENTS" stat={agents?.length || '-'}/>
                    </div>
                    <div>
                        <StatsBox heading="TOTAL COMPANIES" stat={meta?.company || '-'}/>
                    </div>
                </div>
                <div>
                    <div>
                        <StatsBox heading="ACTIVE AGENTS" stat={meta?.activeAgent || '-'}/>
                    </div>
                    <div>
                        <StatsBox heading="ACTIVE COMPANIES" stat={meta?.activeCompany || '-'}/>
                    </div>
                </div>
            </div>}
        </div>
        <div className={styles["data"]}>
            <div className={styles["head"]}>
                <H2>Agents</H2>
                <SubmitBtn text="ADD AGENT" onClick={onAddAgentHandler}/>
            </div>
            {agents?.length <= 0 && <div style={{width:"100%", textAlign:"center"}}><H5>No Agents Found, Create Your First Agent Using Add Agent Button</H5></div>}
            {agents?.length > 0 && <div className={styles["content"]}>
            {window.innerWidth<= 700 && (<>
                {agents?.map(el=><MobileCard agent={el} handleDelete={handleDelete}/>)}
            </>)}
            {window.innerWidth> 700 && (<>
                {/* <div className={styles["top"]}>
                    <div><H5>Agent name</H5></div>
                    <div><H5>Company</H5></div>
                    <div><H5>Total Commission</H5></div>
                    <div><H5>Expense</H5></div>
                    <div><H5>Deals</H5></div>
                </div> */}
                {agents?.map(el=><MobileCard agent={el} handleDelete={handleDelete}/>)}
                </>)}
            </div>}
        </div>
    </div></>
}

export default Deals;