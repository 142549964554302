import { useState } from "react";
import styles from "./index.module.css";

const OutlineBtn = (props)=>{
    const [active,setActive] = useState(false);

    const toggleActive = ()=>{
        setActive(state=>!state);
        props.onClick(active);
    }

    return <div onClick={toggleActive} className={active?`${styles["container"]} ${styles["active"]}`:styles["container"]}>
        {props?.text}
    </div>
}

export default OutlineBtn;