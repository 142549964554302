import { combineReducers } from "@reduxjs/toolkit";

import initReducer from "./slices/init/init.slice";
import authReducer from "./slices/auth/auth.slice";
import projectReducer from "./slices/project/project.slice";
import loaderReducer from "./slices/loader/loader.slice";
import agentReducer from "./slices/agent/agent.slice";
import dealReducer from "./slices/deal/deal.slice";
import expenseReducer from "./slices/expense/expense.slice";
import incomeReducer from "./slices/income/income.slice";


const rootReducer = combineReducers({
  init: initReducer,
  auth:authReducer,
  project:projectReducer,
  loader:loaderReducer,
  agent:agentReducer,
  deal:dealReducer,
  expense:expenseReducer,
  income:incomeReducer,
});

export default rootReducer;