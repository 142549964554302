export const fetchAllAgents = async (__,thunkApi)=>{
    try{
        const {user} = thunkApi.extra.apiService;

        let query = "?";
        for(let key in __){
            query+=`${key}=${__[key]}&`;
        }
        const agents = await user.get(`/agent${query}`);

        return agents;
    }
    catch(err){
        return Promise.reject(err);
    }
}