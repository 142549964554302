import { useDispatch } from "react-redux";
import Form from "../../elements/Form1";
import Input from "../../elements/Input";
import Label from "../../elements/Label";
import SubmitBtn from "../../elements/SubmitBtn";
import H1 from "../../typography/H1";
import H2 from "../../typography/H2";
import H5 from "../../typography/H5";
import HR from "../../typography/HR";
import P from "../../typography/P";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { isEqualTo, isLengthGreaterThan } from "../../../utils/validators";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import { postAgent, updateAgent } from "../../../utils/calls.utils";
import { toast } from "react-toastify";
//
const AgentForm = (props)=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [agent,setAgent]=useState({
        name:{
            value:"",
            error:""
        },
        address:{
            value:"",
            error:""
        },
        city:{
            value:"",
            error:""
        },
        postalCode:{
            value:"",
            error:""
        },
        company:{
            value:"",
            error:""
        },
        phone:{
            value:"",
            error:""
        }
    });

    useEffect(()=>{
    
        if(localStorage.getItem('is_agent_add')!=="true")
        {
            let agent = JSON.parse(localStorage.getItem("agent_"));

            if(!agent) return;

            agent = {
                name:{value:agent?.name,error:""},
                address:{value:agent?.addressLine,error:""},
                city:{value:agent?.city,error:""},
                postalCode:{value:agent?.postalCode,error:""},
                company:{value:agent?.company,error:""},
                phone:{value:agent?.phone,error:""},

            }

            setAgent(agent);
        }
    },[]);

    const onChange = (e)=>{
        setAgent(state=>{
            let newState = JSON.parse(JSON.stringify(state));

            //validations
            if(e?.target?.name=="name"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Agent name is required!";
            }
            else if(e?.target?.name=="name"){
                newState[e?.target?.name].error="";
            }

            // if(e?.target?.name=="address"&&!isLengthGreaterThan(e?.target?.value,0)){
            //     newState[e?.target?.name].error="Agent address is required!";
            // }
            // else if(e?.target?.name=="address"){
            //     newState[e?.target?.name].error="";
            // }

            // if(e?.target?.name=="city"&&!isLengthGreaterThan(e?.target?.value, 0)){
            //     newState[e?.target?.name].error="Agent city is required!";
            // }
            // else if(e?.target?.name=="city"){
            //     newState[e?.target?.name].error="";
            // }
            
            // if(e?.target?.name=="postalCode"&&!isEqualTo(e?.target?.value, 6)){
            //     newState[e?.target?.name].error="Postal code should be 6 digits!";
            // }
            // else if(e?.target?.name=="postalCode"){
            //     newState[e?.target?.name].error="";
            // }

            if(e?.target?.name=="phone"&&!isEqualTo(e?.target?.value, 10)&&!isEqualTo(e?.target?.value, 0)){
                newState[e?.target?.name].error="Phone should be 10 digits!";
            }
            else if(e?.target?.name=="phone"){
                newState[e?.target?.name].error="";
            }

            if(e?.target?.name=="company"&&!isLengthGreaterThan(e?.target?.value, 0)){
                newState[e?.target?.name].error="Agent company is required!";
            }
            else if(e?.target?.name=="company"){
                newState[e?.target?.name].error="";
            }

            newState[e?.target?.name].value = e?.target?.value;

            return newState;
        })
    }

    const onDiscard = ()=>{
        navigate("/dashboard/agents");
    }

    const onSubmit = async (e)=>{
        try{
            if(agent?.name?.error!=""||agent?.address?.error!=""||agent?.city?.error!=""||agent?.postalCode?.error!=""||agent?.phone?.error!=""||agent?.company?.error!=""||agent?.name?.value==""||agent?.company?.value==""){
                return toast.error("Submission invalid!");
            }

            if(localStorage.getItem('is_agent_add')==="true")
                dispatch(showLoader("Creating new Agent, Please wait ..."));
            else
                dispatch(showLoader("Updating agent, Please wait ..."));

            let res;
            if(localStorage.getItem('is_agent_add')==="true")
                res = await postAgent(agent);
            else{
                let id = JSON.parse(localStorage.getItem('agent_'))?._id;

                res = await updateAgent(agent,id);
            }

            navigate("/dashboard/agents");
        }catch(err){
            toast.error('Submission failed! Try again');
            console.log(err);
        }finally{
            dispatch(hideLoader());
        }
    }

    return <div className={styles["container"]}>
            <Form>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>{localStorage.getItem('is_agent_add')==="true"?"Add":"Update"} Agent</H1>
                    <P style={{borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}>You can later use this agent to assign deals belonging to it</P>
                    {/* <HR/> */}
                    <div className={styles["input"]}>
                        <Label>Agent Name*</Label>
                        <Input type="text" placeholder="Prabhjot Singh" name="name" onChange={onChange} error={agent?.name?.error} value={agent?.name?.value} />
                    </div>
                    <div className={styles["input"]}>
                        <Label>Address</Label>
                        <Input type="text" placeholder="Main Street, Southeast" name="address" onChange={onChange} error={agent?.address?.error} value={agent?.address?.value}/>
                    </div>
                    <div className={styles["split"]}>
                        <div className={styles["input"]}>
                            <Label>City</Label>
                            <Input type="text" placeholder="Calgary" name="city" onChange={onChange} error={agent?.city?.error} value={agent?.city?.value}/>
                        </div>
                        <div className={styles["input"]}>
                            <Label>Postal Code</Label>
                            <Input type="text" placeholder="20577" name="postalCode" onChange={onChange} error={agent?.postalCode?.error} value={agent?.postalCode?.value}/>
                        </div>
                    </div>
                    <div className={styles["input"]}>
                        <Label>Company*</Label>
                        <Input type="text" placeholder="Certified Reality" name="company" onChange={onChange} error={agent?.company?.error} value={agent?.company?.value}/>
                    </div>
                    <div className={styles["input"]}>
                        <Label>Phone No</Label>
                        <Input type="text" placeholder="123457890" name="phone" onChange={onChange} error={agent?.phone?.error} value={agent?.phone?.value}/>
                    </div>
                    <div className={styles["cta"]}>
                        <SubmitBtn text={`${localStorage.getItem('is_agent_add')==="true"?"Add":"Update"} Agent`} onClick={onSubmit}/>
                        <H5 style={{color:"var(--color_dark_1)"}} onClick={onDiscard}>Discard</H5>
                    </div>
                </div>
            </Form>
    </div>
}

export default AgentForm;