import { BsLink45Deg } from "react-icons/bs";
import Input from "../../elements/Input";
import Label from "../../elements/Label";
import H2 from "../../typography/H2";
import P from "../../typography/P";
import styles from "./index.module.css";

import React, { useEffect, useRef, useState } from 'react'
import H5 from "../../typography/H5";
import SubmitBtn from "../../elements/SubmitBtn";
import { isEqualTo, isLengthGreaterThan } from "../../../utils/validators";
import { updatePurchaserByIds } from "../../../utils/calls.utils";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import { toast } from "react-toastify";

const ClientForm = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const utilityBillRef = useRef();
  const prOrPassRef = useRef();
  const licenseRef = useRef();

  const [data,setData]= useState({
    first_name:{
        value:"",
        error:""
    },
    last_name:{
        value:"",
        error:""
    },
    designation:{
        value:"",
        error:""
    },
    company:{
        value:"",
        error:""
    },
    address_line:{
        value:"",
        error:""
    },
    city:{
        value:"",
        error:""
    },
    pincode:{
        value:"",
        error:""
    },
    phone:{
        value:"",
        error:""
    },
    driver_license:null,
    pr_or_pass:null,
    utility_bill:null
  });
  const [success,setSucess] = useState(false);

  console.log(data);

  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const dealId = queryParams.get("dealId");
    const purchaserId = queryParams.get("purchaserId");

  },[]);

  const onChange = (e)=>{

    setData(state=>{

        const newState = JSON.parse(JSON.stringify(state));

        newState.utility_bill = state?.utility_bill;
        newState.pr_or_pass = state?.pr_or_pass;
        newState.driver_license = state?.driver_license;

        const name = e?.target?.name;

        if(name=="first_name"&&!isLengthGreaterThan(e?.target?.value,0)){
            newState[name].error = "First Name is required!";
        }
        else if(name=="first_name"){
            newState[name].error = ""
        }

        if(name=="last_name"&&!isLengthGreaterThan(e?.target?.value,0)){
            newState[name].error = "Last Name is required!"
        }
        else if(name=="last_name"){
            newState[name].error = ""
        }

        if(name=="phone"&&!isLengthGreaterThan(e?.target?.value,0)){
            newState[name].error = "Phone No is required!"
        }
        else if(name=="phone"&&!isEqualTo(e?.target?.value,10)){
            newState[name].error = "Invalid Phone No!"
        }
        else if(name=="phone"){
            newState[name].error = ""
        }

        if(name!="pr_or_pass"&&name!="utility_bill"&&name!="driver_license") newState[name].value = e?.target?.value;
        else{
            newState[name] = e?.target?.files[0];
        }

        return newState;
    })
  }

  const onFileHandler = (name)=>{
    if(name=="driver_license") licenseRef.current.click();
    if(name=="pr_or_pass") prOrPassRef.current.click();
    if(name=="utility_bill") utilityBillRef.current.click();
  }

  const onSubmit = async (e)=>{
    try{
        if(data?.first_name?.value==""||data?.first_name?.error!=""||data?.last_name?.value==""||data?.last_name?.error!=""||data?.phone?.value==""||data?.phone?.error!=""){
            return toast.error("Invalid Submission");
        }

        const queryParams = new URLSearchParams(location.search);
        const dealId = queryParams.get("dealId");
        const purchaserId = queryParams.get("purchaserId");

        if(!dealId||!purchaserId) return toast.error("Verify url with url in email! If same contact brokerage!");

        dispatch(showLoader("Submitting your details! Please wait!"));
        const res = await updatePurchaserByIds(dealId,purchaserId,data,true);
        localStorage.setItem("success"+dealId+":"+purchaserId,true);
        setSucess(true);
    }catch(err){
        console.log(err);
        toast.error("Submission failed, Try again!")
    }finally{
        dispatch(hideLoader());
    }
  }
  return (
    <div className={styles["container"]}>
        <div>
            <div className={styles["left"]}>
                <input ref={licenseRef} type="file" name="driver_license" onChange={onChange}  style={{display:"none",visibility:"hidden"}}/>
                <input ref={prOrPassRef} type="file" name="pr_or_pass" onChange={onChange}  style={{display:"none",visibility:"hidden"}}/>
                <input ref={utilityBillRef} type="file" name="utility_bill" onChange={onChange}  style={{display:"none",visibility:"hidden"}}/>
                <div>
                    <div>
                        <div><img style={{width:"calc(12.5 * var(--basex))", marginLeft:"calc(-1 * var(--basex))"}} src="/key2albert.png"/></div>
                    </div>
                    {success && <H5>Details succesfully submitted! You will be contacted soon</H5>}

                    {!success && <>
                    <div>
                        <H2>Thanks for choosing us!</H2>
                    </div>
                    <div>
                        {/* <P>We will require following information to proceed with the deal regarding Seton Serenity</P> */}
                    </div>
                    <div className={styles["split"]}>
                        <div className={styles["input"]}>
                            <Label>Your Name *</Label>
                            <Input 
                            type="text" 
                            placeholder="First Name"
                            name="first_name"
                            onChange={onChange}
                            value={data?.first_name?.value}
                            error={data?.first_name?.error}
                            errorStyle={{color:"white"}}
                            />
                        </div>
                        <div className={styles["input"]}>
                            <Label style={{color:"rgba(0,0,0,0)"}}>.</Label>
                            <Input 
                            type="text" 
                            placeholder="Last Name"
                            name="last_name"
                            onChange={onChange}
                            value={data?.last_name?.value}
                            error={data?.last_name?.error}
                            errorStyle={{color:"white"}}
                            />
                        </div>
                    </div>
                    <div className={styles["split"]}>
                        <div className={styles["input"]}>
                            <Label>Occupation Details</Label>
                            <Input 
                            type="text" 
                            placeholder="Job Title"
                            name="designation"
                            onChange={onChange}
                            value={data?.designation?.value}
                            error={data?.designation?.error}
                            errorStyle={{color:"white"}}
                            />
                        </div>
                        <div className={styles["input"]}>
                            <Label style={{color:"rgba(0,0,0,0)"}}>.</Label>
                            <Input 
                            type="text" 
                            placeholder="Company Name"
                            name="company"
                            onChange={onChange}
                            value={data?.company?.value}
                            error={data?.company?.error}
                            errorStyle={{color:"white"}}
                            />
                        </div>
                    </div>
                    <div className={styles["input"]}>
                            <Label>Address Details</Label>
                            <Input 
                            type="text" 
                            placeholder="Address Line"
                            name="address_line"
                            onChange={onChange}
                            value={data?.address_line?.value}
                            error={data?.address_line?.error}
                            errorStyle={{color:"white"}}
                            />
                    </div>
                    <div className={styles["split"]}>
                        <div className={styles["input"]}>
                            <Label></Label>
                            <Input 
                            type="text" 
                            placeholder="City"
                            name="city"
                            onChange={onChange}
                            value={data?.city?.value}
                            error={data?.city?.error}
                            errorStyle={{color:"white"}}
                            />
                        </div>
                        <div className={styles["input"]}>
                            <Label style={{color:"rgba(0,0,0,0)"}}></Label>
                            <Input 
                            type="text" 
                            placeholder="Postal code"
                            name="pincode"
                            onChange={onChange}
                            value={data?.pincode?.value}
                            error={data?.pincode?.error}
                            errorStyle={{color:"white"}}
                            />
                        </div>
                    </div>
                    <div className={styles["input"]}>
                            <Label>Phone No *</Label>
                            <Input 
                            type="text" 
                            placeholder="1234567890"
                            name="phone"
                            onChange={onChange}
                            value={data?.phone?.value}
                            error={data?.phone?.error}
                            errorStyle={{color:"white"}}
                            />
                    </div>
                    <div>
                            <Label>Documents</Label>
                            <div className={styles["documentBox"]}>
                                <div>
                                    <H5>DRIVER LICENSE</H5>
                                </div>
                                <div onClick={()=>onFileHandler("driver_license")}>
                                    <BsLink45Deg/>
                                </div>
                            </div>
                            <div className={styles["filename"]}>{data?.driver_license?.name}</div>
                            <div className={styles["documentBox"]}>
                                <div>
                                    <H5>PR CARD OR PASSPORT</H5>
                                </div>
                                <div onClick={()=>onFileHandler("pr_or_pass")}>
                                    <BsLink45Deg/>
                                </div>
                            </div>
                            <div className={styles["filename"]}>{data?.pr_or_pass?.name}</div>
                            <div className={styles["documentBox"]} >
                                <div>
                                    <H5>ANY UTILITY BILL</H5>
                                </div>
                                <div onClick={()=>onFileHandler("utility_bill")}>
                                    <BsLink45Deg/>
                                </div>
                            </div>
                            <div className={styles["filename"]} style={{marginBottom:"calc(4 * var(--basey))"}}>{data?.utility_bill?.name}</div>
                            <div className={styles["cta"]}>
                                <SubmitBtn text="Submit" onClick={onSubmit} style={{color:"var(--color_dark_1)",backgroundColor:"white"}}/>
                            </div>
                    </div></>}
                </div>
            </div>
            <div className={styles["right"]}>
               <H2 style={{fontSize:"5rem"}}>FINDING YOUR DREAM HOME HAS NEVER BEEN SO EASY!</H2>
            </div>
        </div>
    </div>
  )
}

export default ClientForm