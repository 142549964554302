import { BsFilterLeft, BsSearch, BsXCircle } from "react-icons/bs";
import Input from "../Input";
import styles from "./index.module.css";
import MultiSelectDropDown from "../MultiSelectDropdown";
import NameDisplay from "../NameDisplay";
import H2 from "../../typography/H2";
import SubmitBtn from "../SubmitBtn";
import H5 from "../../typography/H5";
import OutlineBtn from "../OutlineBtn";
import StatsBox from "../StatsBox";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgentsAsyncThunk } from "../../../store/slices/agent/agent.slice";
import { deleteAgentById, deleteExpense, fetchUniqueCompanies } from "../../../utils/calls.utils";
import { useNavigate } from "react-router-dom";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import Modal from "../Modal";
import { fetchAllExpensesAsyncThunk } from "../../../store/slices/expense/expense.slice";
import { toast } from "react-toastify";
import { getAccessToken } from "../../../utils/global/auth.global";

const MobileCard = ({expense,handleDelete})=>{
    const navigate = useNavigate();

    const handleUpdate = (e)=>{
        localStorage.setItem("is_expense_add",false);
        localStorage.setItem("expense_",JSON.stringify(expense));

        navigate("/expenses");
    }
    return <div className={styles["card"]}>
        <div>
            <div className={styles["cta"]} onClick={()=>handleDelete(expense)}><BsXCircle/></div>
        </div>
        <div>
            <div>
                <H5>Label</H5>
                <div>{expense?.label}</div>
            </div>
            <div>
                <H5>Amount</H5>
                <div>{expense?.amount}</div>
            </div>
        </div>
        <div>
            <div>
                <H5>Date</H5>
                <div>{expense?.date?.split("T")[0]}</div>
            </div>
            <div>
                <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
            </div>
        </div>
    </div>;
}

const DesktopCard = ({expense,handleDelete})=>{
    const navigate = useNavigate();

    const handleUpdate = (e)=>{
        localStorage.setItem("is_expense_add",false);
        localStorage.setItem("expense_",JSON.stringify(expense));

        navigate("/expenses");
    }
   
    return <div className={styles["row"]}>
        <div>{expense?.label}</div>
        <div>{expense?.amount}</div>
        <div>{expense?.date?.split("T")[0]}</div>
        <div className={styles["cta"]} onClick={handleUpdate}>Update</div>
        <div className={styles["cta"]} onClick={()=>handleDelete(expense)}><BsXCircle/></div>
    </div>
}
const Expenses = (props)=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const expenses = useSelector(state=>state.expense.expenses);
    const meta = useSelector(state=>state.expense.meta);
    const [name,setName] = useState("");
    const [beforeDate,setBeforeDate] = useState(null);
    const [afterDate,setAfterDate] = useState(null);

    const [modal,setModal] = useState({show:false,data:null});
    const [load,setLoad] = useState(false);
    const [user,setUser] = useState({});

    const onNameChange =(e)=>{
        setName(e?.target?.value);
    }

    useEffect(()=>{
        if(localStorage.getItem("user_")) setUser(JSON.parse(localStorage.getItem("user_")));
    },[]);
   

    useEffect(()=>{
        let query={};

        if(name) query.label=name;
        if(afterDate) query.after_date = afterDate;
        if(beforeDate) query.before_date = beforeDate;

        console.log(afterDate);

        dispatch(fetchAllExpensesAsyncThunk(query));
        dispatch(fetchAllAgentsAsyncThunk({}));
    },[name,load,afterDate,beforeDate]);

    const onAddExpenseHandler = (e)=>{
        localStorage.setItem("is_expense_add",true);
        navigate("/expenses");
    }

    const handleDelete = (expense)=>{
        setModal({show:true,data:expense});
    }

    const onAction =async (e)=>{
        try{
            dispatch(showLoader(`Deleting ${modal?.data?.label}, Please wait..`));
            await deleteExpense(modal?.data?._id);
            toast.success("Expense deleted succesfully");
        }catch(err){
            console.log(err);
            toast.error("Expense deletion failed");
        }finally{
            setLoad(state=>!state);
            dispatch(hideLoader());
            setModal({show:false,data:null});
        }
    }

    const onClose =  (e)=>{
        setModal({show:false,data:null});
    }
    
    return <>
    {modal.show && <Modal 
    onAction={onAction}
    onClose={onClose}
    action={"delete"}
    message={`Are you sure you want to delete ${modal?.data?.label}`}
    />}
    <div className={styles["container"]}>
        <div className={styles["filters"]}>
            <div className={styles["btns"]}>
                <div>
                    <div className={styles["input"]}>
                        <Input type="text" placeholder="Rentals ..." error={"Search label"} onChange={onNameChange} value={name}/>
                    </div>
                    <div className={styles["input"]}>
                        <Input type="date" value={afterDate} onChange={(e)=>setAfterDate(e?.target?.value)} error={"After date"}/>
                    </div>
                    <div className={styles["input"]}>
                        <Input type="date" value={beforeDate} onChange={(e)=>setBeforeDate(e?.target?.value)} error={"Before date"}/>
                    </div>
                </div>
                <div>
                    <NameDisplay name={`${user?.firstName} ${user?.lastName}`}/>
                </div>
            </div>
            {user?.role=="admin" && <div className={styles["stats"]}>
                <div>
                    <div>
                        <StatsBox heading="TOTAL EXPENSE" stat={meta?.total_expense?parseInt(meta?.total_expense):0}/>
                    </div>
                    <div>
                        <StatsBox heading="COMMISSION EARNED" stat={meta?.commission_earned?parseInt(meta?.commission_earned):0}/>
                    </div>
                    <div>
                        <StatsBox heading="COMMISSION DUE" stat={meta?.commission_due?parseInt(meta?.commission_due):0}/>
                    </div>
                </div>
                {/* <div>
                    <div>
                        <StatsBox heading="ACTIVE AGENTS" stat={meta?.activeAgent}/>
                    </div>
                    <div>
                        <StatsBox heading="ACTIVE COMPANIES" stat={meta?.activeCompany}/>
                    </div>
                </div> */}
            </div>}
        </div>
        <div className={styles["data"]}>
            <div className={styles["head"]}>
                <H2>Expenses</H2>
                <SubmitBtn text="ADD EXPENSE" onClick={onAddExpenseHandler}/>
            </div>
            {expenses?.length <= 0 && <div style={{width:"100%", textAlign:"center"}}><H5>No Expenses Found, Create Your First Expense Using Add Expense Button</H5></div>}
            {expenses?.length > 0 && <div className={styles["content"]}>
            {window.innerWidth<= 700 && (<>
                {expenses?.map(el=><MobileCard expense={el} handleDelete={handleDelete}/>)}
            </>)}
            {window.innerWidth> 700 && (<>
                <div className={styles["top"]}>
                    <div><H5>Label</H5></div>
                    <div><H5>Amount</H5></div>
                    <div><H5>Date</H5></div>
                </div>
                {expenses?.map(el=><DesktopCard expense={el} handleDelete={handleDelete}/>)}
                </>)}
            </div>}
        </div>
        {user.role=="admin" && <a href={process.env.REACT_APP_API_DOMAIN+"/expense/exports?jwt="+getAccessToken()} target="_blank" className={styles["export"]}>Export</a>}
    </div></>
}

export default Expenses;