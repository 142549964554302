import { BsChevronDown } from "react-icons/bs";
import styles from "./index.module.css";
import Checkbox from "../Checkbox";
import { useEffect, useState } from "react";

const MultiSelectDropDown = ({options, onChange,title}) => {
  const [expand,setExpand] = useState(false);
  const [opts,setOpts] = useState({});

  useEffect(()=>{
    let newOpts = {};

    options?.forEach(el=>{newOpts[el]=false;})
    
    setOpts(newOpts);
  },[options]);

  const onChang = (name)=>{
    if(!name) return;

    setOpts(state=>{
      let newOpts = JSON.parse(JSON.stringify(state));

      newOpts[name]=!newOpts[name];

      onChange(newOpts);
      return newOpts;
    })
  }

  const toggleExpand = ()=>{
    setExpand(state=>!state);
  }

  return (
    <div className={styles["container"]}>
        <div className={styles["label"]} onClick={toggleExpand}>{title} <div style={{transform:`rotate(${expand?"180deg":"0deg"})`}}><BsChevronDown/></div></div> 
        <div className={styles["options"]} style={{transform:`scale(${expand?"1":"0"})`, transformOrigin:"top"}}>
            {
              options && options?.map((option,el)=>{
                return <div key={el} className={styles["option"]}>
                  <Checkbox size="20" name={option} onChange={onChang}/> <div>{option}</div>
                </div>
              })
            }
        </div>
    </div>
  )
}

export default MultiSelectDropDown