export const fetchAllDealsHandler = async (__,thunkApi)=>{
    try{
        const {user} = thunkApi.extra.apiService;

        let query = "?";
        for(let key in __){
            query+=`${key}=${__[key]}&`;
        }
        const deals = await user.get(`/deal${query}`);
        
        return deals;
    }catch(err){
        console.log(err);
        return Promise.reject(err);
    }
}

export const updateDealStatusById = async (__,thunkApi)=>{
    try{
        const {user} = thunkApi.extra.apiService;

        const deal = await user.patch("/deal/"+__.id+"/status",{status:__.selected});

        return deal;
    }
    catch(err){
        console.log(err);
        return Promise.reject(err)
    }
}

export const updateDealMortgageByIdHandler = async (__,thunkApi)=>{
    try{
        const {user} = thunkApi.extra.apiService;

        const deal = await user.patch("/deal/"+__.id+"/mortgage",{mortgage:__.mortgage});

        return deal;
    }
    catch(err){
        console.log(err);
        return Promise.reject(err)
    }
}

export const updateDealRealizedByIdHandler = async (__,thunkApi)=>{
    try{
        const {user} = thunkApi.extra.apiService;

        const deal = await user.patch("/deal/"+__.id+"/realized",{realized:__.realized});

        return deal;
    }
    catch(err){
        console.log(err);
        return Promise.reject(err)
    }
}