import styles from "./index.module.css";

import React from 'react'

const NameDisplay = (props) => {
  return (
    <div className={styles["container"]}>{props.name}</div>
  )
}

export default NameDisplay