import './App.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";

import Home from "./components/pages/Home";
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import Projects from './components/elements/Projects';
import Deals from './components/elements/Deals';
import Agents from './components/elements/Agents';
import ProjectForm from './components/pages/ProjectForm';
import AgentForm from './components/pages/AgentForm';
import DealDetails from './components/pages/DealDetails';
import DealForm from './components/pages/DealForm';
import ClientForm from './components/pages/ClientForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAccessToken } from './utils/global/auth.global';
import BuilderForm from './components/pages/BuilderForm';
import Loader from './components/pages/Loader';
import { showLoader } from './store/slices/loader/loader.slice';
import Expenses from './components/elements/Expenses';
import Incomes from './components/elements/Incomes';
import ExpenseForm from './components/pages/ExpenseForm';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IncomeForm from './components/pages/IncomeForm';

function App() {
  
  const loader = useSelector(state=>state.loader);
  const isLoggedIn = useSelector(state=>state.auth.isLoggedIn);

  useEffect(()=>{
    if(window.location.pathname!="/"&&!getAccessToken()){
      if(!window.location.pathname.startsWith("/client/form"))
      window.location.replace("/");
    }
  },[isLoggedIn])

  return (
    <>
      {loader?.showLoader && <Loader/>}
      <ToastContainer/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path='/dashboard/projects' element={<Projects/>}/>
            <Route path='/dashboard/deals' element={<Deals/>}/>
            <Route path='/dashboard/agents' element={<Agents/>}/>
            <Route path='/dashboard/expenses' element={<Expenses/>}/>
            <Route path='/dashboard/incomes' element={<Incomes/>}/>
          </Route>
          <Route path='/builders' element={<BuilderForm/>}/>
          <Route path="/projects" element={<ProjectForm/>}/>
          <Route path="/agents" element={<AgentForm/>}/>
          <Route path="/expenses" element={<ExpenseForm/>}/>
          <Route path="/incomes" element={<IncomeForm/>}/>
          <Route path="/deals/view" element={<DealDetails/>}/>
          <Route path="/deals/form" element={<DealForm/>}/>
          <Route path="/client/form" element={<ClientForm/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
