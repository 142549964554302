import { userAxios } from "./axios/user.axios";

export const redirectUsingFileName = async (name)=>{
    const res = await userAxios("/deal/document?name="+name);

    const a = document.createElement('a');
    a.href = res?.data;
    a.target="_blank";
    document.body.appendChild(a);
    a.click();
}