import styles from "./index.module.css";

import React from 'react'

const StatsBox = (props) => {
  return (
    <div className={styles["container"]}>
        <div>{props?.heading}</div>
        <div>{props?.stat}</div>
    </div>
  )
}

export default StatsBox