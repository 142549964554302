import { useEffect, useState } from "react";
import NavItem from "../NavItem";
import styles from "./index.module.css";
import {BsBuildings, BsPeople, BsCurrencyDollar} from "react-icons/bs";
import {RiBillFill} from "react-icons/ri";
import {CiLogout} from "react-icons/ci";
import {TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarRightCollapseFilled} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/slices/auth/auth.slice";
import { FaMoneyBillAlt } from "react-icons/fa";

// import logo from "./../../../../public/logo192.png";

const SideMenu = (props)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [expand,setExpand] = useState(true);
    const [user,setUser] = useState({});
   
    useEffect(()=>{
        if(localStorage.getItem("user_")) setUser(JSON.parse(localStorage.getItem("user_")));
    },[]);

    const location = useLocation();

    const toggleExpand = ()=>{
        setExpand(state=>!state);
    }

    const onLogout = ()=>{
        dispatch(logout())
        navigate("/");
    }

    return <div className={expand?styles["container"]:`${styles["container"]} ${styles["collapse"]}`}>
        <div className={styles["cta"]} onClick={toggleExpand}>
            {expand?<TbLayoutSidebarLeftCollapseFilled/>:<TbLayoutSidebarRightCollapseFilled/>}
        </div>
        <div className={expand?styles["logo"]:`${styles["logo"]} ${styles["hide"]}`}>
            <div><img src="/key2albertaaaa.png"/></div>
        </div>
        <div className={expand?styles["nav"]:`${styles["nav"]} ${styles["hide"]}`}>
            <NavItem icon={<BsBuildings/>} text="Projects" path="projects" isActive={location?.pathname?.endsWith("projects")}/>
            <NavItem icon={<BsCurrencyDollar/>}  path="deals" text="Deals" isActive={location?.pathname?.endsWith("deals")}/>
            {user?.role=="admin" && <NavItem icon={<BsPeople/>} path="agents" text="Agents" isActive={location?.pathname?.endsWith("agents")}/>}
            {user?.role=="admin" && <NavItem icon={<RiBillFill/>} path="expenses" text="Expenses" isActive={location?.pathname?.endsWith("expenses")}/>}
            {user?.role=="admin" && <NavItem icon={<FaMoneyBillAlt />} path="incomes" text="Incomes" isActive={location?.pathname?.endsWith("incomes")}/>}
        </div>
        <div onClick={onLogout} className={expand?styles["logout"]:`${styles["logout"]} ${styles["hide"]}`}>
            <div>
                <CiLogout/>
                Logout
            </div>
        </div>
    </div>
}

export default SideMenu;