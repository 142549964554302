//node modules import
import React, { useEffect, useRef, useState } from 'react'
import { BsChevronDown, BsDownload, BsLink45Deg, BsX } from "react-icons/bs";
import { useDispatch } from "react-redux";

//custom modules import
import P from "../../typography/P";
import Input from "../Input";
import Label from "../Label";
import SubmitBtn from "../SubmitBtn";
import styles from "./index.module.css";
import H5 from "../../typography/H5";
import { isDateMMDDYYYY, isEmail, isEqualTo, isLengthGreaterThan } from "../../../utils/validators";
import { deletePurchasersById, fetchDealById, postPurchaserByDealId, sendEmailToPurchaserByIds, updatePurchaserByIds } from "../../../utils/calls.utils";
import { hideLoader, showLoader } from "../../../store/slices/loader/loader.slice";
import { useNavigate } from 'react-router-dom';
import { redirectUsingFileName } from '../../../utils/download.utils';
import { toast } from 'react-toastify';


//PREFILLED BOX IS SHOWN BEFORE USER ENTERS HIS INFO FROM EMAIL
const PrefilledBox = ({sendEmail,purchaser, onPurchaserDelete})=>{

    //HANDLERS
    const onDeleteHandler = async (e)=>{
        await onPurchaserDelete(purchaser)
    }

    const resendEmail = ()=> sendEmail(false,purchaser?._id);

    return <div className={styles["email"]}>
        <div>
            <Input editable={false} value={purchaser?.email} style={{borderTopRightRadius:0,borderBottomRightRadius:0}} type="text" placeholder="purchaser@gmail.com"/>
            <div onClick={onDeleteHandler}><BsX/></div>
        </div>
        <div onClick={resendEmail} style={{cursor:"pointer"}}>
            Resend
        </div>
    </div>
}

//FILLED BOX IS SHOWN AFTER USER ENTERS HIS INFO FROM EMAIL
const FilledBox = ({syncData,sendEmail,purchaser, onPurchaserDelete,idx})=>{

    //Hooks
    const dispatch = useDispatch();


    //State declarations
    const [expand,setExpand] = useState(true);
    const [data,setData] = useState({});
    
    //Refs
    const driverLicenseRef = useRef();
    const prCardRef = useRef();
    const utilityBillRef = useRef();

    const [docs,setDocs] = useState({
        driver_license:null,
        pr_or_pass:null,
        utility_bill:null
    });
    //Use effects
    useEffect(()=>{
        setData({
            email:{
                value:purchaser?.email,
                error:purchaser?.email_e?purchaser?.email_e:""
            },
            first_name:{
                value:purchaser?.first_name?purchaser?.first_name:"",
                error:purchaser?.first_name_e?purchaser?.first_name_e:""
            },
            last_name:{
                value:purchaser?.last_name?purchaser?.last_name:"",
                error:purchaser?.last_name_e?purchaser?.last_name_e:""
            },
            address_line:{
                value:purchaser?.address_line?purchaser?.address_line:"",
                error:purchaser?.address_line_e?purchaser?.address_line_e:""
            },
            city:{
                value:purchaser?.city?purchaser?.city:"",
                error:purchaser?.city_e?purchaser?.city_e:""
            },
            company:{
                value:purchaser?.company?purchaser?.company:"",
                error:purchaser?.company_e?purchaser?.company_e:""
            },
            designation:{
                value:purchaser?.designation?purchaser?.designation:"",
                error:purchaser?.designation_e?purchaser?.designation_e:""
            },
            phone:{
                value:purchaser?.phone?purchaser?.phone:"",
                error:purchaser?.phone_e?purchaser?.phone_e:""
            },
            pincode:{
                value:purchaser?.pincode?purchaser?.pincode:"",
                error:purchaser?.pincode_e?purchaser?.pincode_e:""
            },
            dob:{
                value:purchaser?.dob?.split("T")[0]?purchaser?.dob?.split("T")[0]:"",
                error:purchaser?.dob_e?purchaser?.dob_e:""
            },
            driver_license:purchaser?.driver_license_f || null,
            pr_or_pass: purchaser?.pr_or_pass_f || null,
            utility_bill: purchaser?.utility_bill_f || null
        })
        setDocs({
            driver_license:purchaser?.driver_license || null,
            pr_or_pass:purchaser?.pr_or_pass || null,
            utility_bill:purchaser?.utility_bill || null
        })
    },[purchaser]);


    //Event handlers
    const onDeleteHandler = async (e)=>{
        await onPurchaserDelete(purchaser,idx)
    }

    const resendEmail = ()=> sendEmail(false,purchaser?._id);

    const onGetFile = (name)=>{

        if(name=="driver_license") driverLicenseRef.current.click();
        if(name=="pr_or_pass") prCardRef.current.click();
        if(name=="utility_bill") utilityBillRef.current.click();
        
    }

    const onFileChange = (e)=>{
        setData(state=>{
            const newState = JSON.parse(JSON.stringify(state));
            const newPurchaser = JSON.parse(JSON.stringify(purchaser));

            newState.pr_or_pass = state.pr_or_pass;
            newState.utility_bill = state.utility_bill;
            newState.driver_license = state.driver_license;

            newPurchaser.pr_or_pass_f = purchaser.pr_or_pass_f;
            newPurchaser.utility_bill_f = purchaser.utility_bill_f;
            newPurchaser.driver_license_f = purchaser.driver_license_f;

            newState[e?.target?.name] = e?.target?.files[0];
            newPurchaser[e?.target?.name+"_f"] = e?.target?.files[0];

            console.log(purchaser,newPurchaser);
            syncData(newPurchaser,idx);

            return newState;
        })
    }
    
    
    const onChange = (e)=>{
    
        setData(state=>{
            const newState = JSON.parse(JSON.stringify(state));
            const newPurchaser = JSON.parse(JSON.stringify(purchaser));

            newPurchaser.pr_or_pass_f = purchaser.pr_or_pass_f;
            newPurchaser.utility_bill_f = purchaser.utility_bill_f;
            newPurchaser.driver_license_f = purchaser.driver_license_f;

            newPurchaser.email = state?.email?.value?state?.email?.value:"";
            newPurchaser.email_e = state?.email?.error?state?.email?.error:"";

            newPurchaser.first_name = state?.first_name?.value?state?.first_name?.value:"";
            newPurchaser.first_name_e = state?.first_name?.error?state?.first_name?.error:"";

            newPurchaser.last_name = state?.last_name?.value?state?.last_name?.value:"";
            newPurchaser.last_name_e = state?.last_name?.error?state?.last_name?.error:"";

            newPurchaser.address_line= state?.address_line?.value?state?.address_line?.value:"";
            newPurchaser.address_line_e= state?.address_line?.error?state?.address_line?.error:"";

            newPurchaser.city = state?.city?.value?state?.city?.value:"";
            newPurchaser.city_e = state?.city?.error?state?.city?.error:"";

            newPurchaser.company = state?.company?.value?state?.company?.value:"";
            newPurchaser.company_e = state?.company?.error?state?.company?.error:"";

            newPurchaser.designation = state?.designation?.value?state?.designation?.value:"";
            newPurchaser.designation_e = state?.designation?.error?state?.designation?.error:"";

            newPurchaser.phone = state?.phone?.value?state?.phone?.value:"";
            newPurchaser.phone_e = state?.phone?.error?state?.phone?.error:"";

            newPurchaser.pincode= state?.pincode?.value?state?.pincode?.value:"";
            newPurchaser.pincode_e= state?.pincode?.error?state?.pincode?.error:"";

            newPurchaser.dob = state?.dob?.value?state?.dob?.value:"";
            newPurchaser.dob_e = state?.dob?.error?state?.dob?.error:"";


            if(e?.target?.name=="email"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Email is required!";
                newPurchaser[e?.target?.name+"_e"]="Email is required!";
            }
            else if(e?.target?.name=="email"&&!isEmail(e?.target?.value,0)){
                newState[e?.target?.name].error="Invalid Email!";
                newPurchaser[e?.target?.name+"_e"]="Invalid Email!";
            }
            else if(e?.target?.name=="email"){
                newState[e?.target?.name].error="";
                newPurchaser[e?.target?.name+"_e"]="";
            }

            if(e?.target?.name=="first_name"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="First Name is required!";
                newPurchaser[e?.target?.name+"_e"]="First Name is required!";
            }
            else if(e?.target?.name=="first_name"){
                newState[e?.target?.name].error="";
                newPurchaser[e?.target?.name+"_e"]="";
            }

            if(e?.target?.name=="first_name"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="First Name is required!";
                newPurchaser[e?.target?.name+"_e"]="First Name is required!";
            }
            else if(e?.target?.name=="first_name"){
                newState[e?.target?.name].error="";
                newPurchaser[e?.target?.name+"_e"]="";
            }

            if(e?.target?.name=="last_name"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Last Name is required!";
                newPurchaser[e?.target?.name+"_e"]="Last Name is required!";
            }
            else if(e?.target?.name=="last_name"){
                newState[e?.target?.name].error="";
                newPurchaser[e?.target?.name+"_e"]="";
            }

            if(e?.target?.name=="phone"&&!isLengthGreaterThan(e?.target?.value,0)){
                newState[e?.target?.name].error="Phone is required!";
                newPurchaser[e?.target?.name+"_e"]="Phone is required!";
            }
            else if(e?.target?.name=="phone"&&!isEqualTo(e?.target?.value,10)){
                newState[e?.target?.name].error="Invalid Phone No!";
                newPurchaser[e?.target?.name+"_e"]="Invalid Phone No!";
            }
            else if(e?.target?.name=="phone"){
                newState[e?.target?.name].error="";
                newPurchaser[e?.target?.name+"_e"]="";
            }
            
            if(e?.target?.name=="dob"&&!isDateMMDDYYYY(e?.target?.value,10)){
                newState[e?.target?.name].error="Invalid D.O.B!";
                newPurchaser[e?.target?.name+"_e"]="Invalid D.O.B!";
            }
            else if(e?.target?.name=="dob"){
                newState[e?.target?.name].error="";
                newPurchaser[e?.target?.name+"_e"]="";
            }

            newPurchaser[e?.target?.name] = e?.target?.value;
            newState[e?.target?.name].value= e?.target?.value;
    
            syncData(newPurchaser,idx);

            return newState;
            
        })
        
    }

    const onDownload =async (name)=>{
        await redirectUsingFileName(name);
    }

    const onSubmit = async (e)=>{
        if(data?.first_name?.value==""
        || data?.first_name?.error!=""
        || data?.last_name?.value==""
        || data?.last_name?.error!=""
        || data?.phone?.value==""
        || data?.phone?.error!=""
        ) return toast.error("Invalid submission");

        try{
            dispatch(showLoader("Saving purchaser info, Please wait!"));

            let dealId = localStorage.getItem("deal_");
            if(!dealId) return toast.error("Please go to dashboard and comeback!");

            dealId = JSON.parse(dealId)?._id;

            const res = await updatePurchaserByIds(dealId,purchaser._id,data);

            dispatch(hideLoader());

            toast.success("purchaser updated succesfully!")
        }catch(err){
            console.log(err);
            toast.error("Submission failed! Try again")
        }finally{
            dispatch(hideLoader());
        }
        
    }

    //Util function
    const toggleExpand = ()=>{
        setExpand(state=>!state);
    }

    //JSX
    return <div className={expand?styles["card"]:`${styles["card"]} ${styles["collapse"]}`}>
    <div>
        <div>
            <H5>{data?.email?.value}</H5>
            <div onClick={toggleExpand} style={{transform:expand?"rotate(180deg)":""}}>
                <BsChevronDown/>
            </div>
        </div>
        <div className={styles["input"]}>
            <Label>Email</Label>
            <Input type="text" placeholder="abc@gmail.com" name="email" onChange={onChange} value={data?.email?.value} error={data?.email?.error}/>
        </div>
        <div className={styles["split"]}>
            <div className={styles["input"]}>
                <Label>Your Name</Label>
                <Input type="text" placeholder="John" name="first_name" onChange={onChange} value={data?.first_name?.value} error={data?.first_name?.error}/>
            </div>
            <div className={styles["input"]}>
                <Label style={{color:"rgba(0,0,0,0)"}}>.</Label>
                <Input type="text" placeholder="Doe" name="last_name" onChange={onChange} value={data?.last_name?.value} error={data?.last_name?.error}/>
            </div>
        </div>
        <div className={styles["split"]}>
            <div className={styles["input"]}>
                <Label>Occupation Details</Label>
                <Input type="text" placeholder="Accountant" name="designation" onChange={onChange} value={data?.designation?.value} error={data?.designation?.error}/>
            </div>
            <div className={styles["input"]}>
                <Label style={{color:"rgba(0,0,0,0)"}}>.</Label>
                <Input type="text" placeholder="S&P Corp" name="company" onChange={onChange} value={data?.company?.value} error={data?.company?.error}/>
            </div>
        </div>
        <div className={styles["input"]}>
                <Label>Address Details</Label>
                <Input type="text" placeholder="Address Line" name="address_line" onChange={onChange} value={data?.address_line?.value} error={data?.address_line?.error}/>
        </div>
        <div className={styles["split"]}>
            <div className={styles["input"]}>
                <Label></Label>
                <Input type="text" placeholder="City" name="city" onChange={onChange} value={data?.city?.value} error={data?.city?.error}/>
            </div>
            <div className={styles["input"]}>
                <Label></Label>
                <Input type="text" placeholder="Postal code" name="pincode" onChange={onChange} value={data?.pincode?.value} error={data?.pincode?.error}/>
            </div>
        </div>
        <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
            <Label>Phone No</Label>
            <Input type="number" placeholder="1234567890" name="phone" onChange={onChange} value={data?.phone?.value} error={data?.phone?.error}/>
        </div>
        <div className={styles["input"]}>
            <Label>Documents</Label>
        </div>
        <input type="file" name="driver_license" onChange={onFileChange} style={{display:"none",visibility:"hidden"}} ref={driverLicenseRef}/>
        <input type="file" name="pr_or_pass" onChange={onFileChange} style={{display:"none",visibility:"hidden"}} ref={prCardRef}/>
        <input type="file" name="utility_bill" onChange={onFileChange} style={{display:"none",visibility:"hidden"}} ref={utilityBillRef}/>
        <div className={styles["documentBox"]}>
            <div>
                <H5>Driver License</H5>
            </div>
            <div>
                {docs?.driver_license &&<BsDownload onClick={(e)=>onDownload(docs?.driver_license)}/>}
            </div>
            <div onClick={e=>onGetFile("driver_license")} name="driver_license">
                <BsLink45Deg name="driver_license"/>
            </div>
        </div>
        <div className={styles["filename"]}>{data?.driver_license?.name}</div>
        <div className={styles["documentBox"]}>
            <div>
                <H5>PR Card Or Passport</H5>
            </div>
            <div>
                {docs?.pr_or_pass &&<BsDownload onClick={(e)=>onDownload(docs?.pr_or_pass)}/>}
            </div>
            <div onClick={e=>onGetFile("pr_or_pass")} name="pr_or_pass">
                <BsLink45Deg/>
            </div>
        </div>
        <div className={styles["filename"]}>{data?.pr_or_pass?.name}</div>
        <div className={styles["documentBox"]} >
            <div>
                <H5>Any Utility Bill</H5>
            </div>
            <div>
                {docs?.utility_bill &&<BsDownload onClick={(e)=>onDownload(docs?.utility_bill)}/>}
            </div>
            <div onClick={e=>onGetFile("utility_bill")} name="utility_bill">
                <BsLink45Deg/>
            </div>
        </div>
        <div className={styles["filename"]} style={{marginBottom:"calc(2 * var(--basey))"}}>{data?.utility_bill?.name}</div>
        <div className={styles["input"]} style={{marginBottom:"calc(2 * var(--basey))"}}>
            <Label>DOB</Label>
            <Input type="date" name="dob" onChange={onChange} value={data?.dob?.value} error={data?.dob?.error}/>
        </div>
        <div className={styles["input"]} style={{marginBottom:"calc(1 * var(--basey))"}}>
            <SubmitBtn text="Send Mail" onClick={resendEmail}/>
        </div>
        <div className={styles["cta"]}>
            <SubmitBtn text="Save" onClick={onSubmit}/>
            <H5 onClick={onDeleteHandler} style={{cursor:'pointer',color:"var(--color_dark_1)"}}>Delete</H5>
        </div>
    </div>
</div>;
}

//Main component 
const ProjectInfo = ({onNext}) => { 

  //State declarations
  const [purchashers,setPurchasers] = useState([]);
  const [reload,setReload] = useState(false);
  const [email,setEmail] = useState({value:"",error:""});
  const [status,setStatus] = useState('');

  const navigate = useNavigate();

  //Use effects
  useEffect(()=>{
    let id = localStorage.getItem("deal_");
    
    if(!id) return;
    
    id = JSON.parse(id);
    setStatus(id?.status);
    id=id?._id;
    
    fetchDealById(id)
        .then(res=>{
            localStorage.setItem("deal_",JSON.stringify(res?.data));
            setPurchasers(res?.data?.purchasers);
        })
        .catch(err=>console.log(err));

  },[reload]);

  //Utility functions
  const toggleReload = () => setReload(state=>!state);
  const sendEmail = async (isSend,purchaserId="",email="",noEmail=false)=>{
    try{
        if(isSend)
        if(email?.value==""||email?.error!="") return toast.error("Invalid submission");

        let id = localStorage.getItem("deal_");

        if(!id) return toast.error("Please go to dashboard and come again!");

        id = JSON.parse(id)?._id;

        let res,res2;
        if(isSend)
        res = await postPurchaserByDealId(id,email?.value);
    
        if(!noEmail){
            if(isSend)
            res2 = await sendEmailToPurchaserByIds(id,res?.data?._id);
            else
            res2 = await sendEmailToPurchaserByIds(id,purchaserId);
        }

        if(isSend)
        setPurchasers(state=>{
            const newState = JSON.parse(JSON.stringify(state));

            for(let i=0;i<newState?.length;i++){
                newState[i].driver_license_f = state[i].driver_license_f;
                newState[i].utility_bill_f = state[i].utility_bill_f;
                newState[i].pr_or_pass_f = state[i].pr_or_pass_f;
            }
            newState.push(res?.data);
            return newState;
        })

        if(isSend)
        toast.success("Purchaser added successfully");
        else
        toast.success("Email sent succesfull!");

    }catch(err){
        toast.error("Failure! Try again");
        console.log(err);
    }
  }
  const syncData = (data,idx)=>{
    setPurchasers(state=>{
        const newState = JSON.parse(JSON.stringify(state));
        for(let i=0;i<newState?.length;i++){
            newState[i].driver_license_f = state[i].driver_license_f;
            newState[i].utility_bill_f = state[i].utility_bill_f;
            newState[i].pr_or_pass_f = state[i].pr_or_pass_f;
        }
        newState[idx]= data;
        return newState;
    })
  }
  const debounce = (fn,ms)=>{
    let timer;
    return (...args)=>{
        clearTimeout(timer);
        timer = setTimeout(()=>{fn.apply(this,args);},ms);
    }
  }

const syncDataDebounce = debounce((newPurchaser,idx)=>syncData(newPurchaser,idx),1000);
  const deleteFromIndex = (idx)=>{
    setPurchasers(state=>{
        const newState = JSON.parse(JSON.stringify(state));
        for(let i=0;i<newState?.length;i++){
            newState[i].driver_license_f = state[i].driver_license_f;
            newState[i].utility_bill_f = state[i].utility_bill_f;
            newState[i].pr_or_pass_f = state[i].pr_or_pass_f;
        }
        newState.splice(idx,1);
        return newState;
    })
  }

  //Event handlers
  const onPurchaserDelete = async (purchaser,idx)=>{
        try{
            let dealId = localStorage.getItem("deal_");

            if(!dealId) throw new Error('Deal not found in localstorage');

            dealId = JSON.parse(dealId)?._id;

            await deletePurchasersById(dealId,purchaser?._id);

            // toggleReload();
            deleteFromIndex(idx);
            
        }catch(err){
            console.log(err);
            toast.error("Deletion failed! Try again")
        }
    }

  const onChange = (e)=>{
    
    setEmail(state=>{
      const newState = JSON.parse(JSON.stringify(state));

      if(e?.target?.value==""){
        newState.error = "Email is required!"
      }
      else if(!isEmail(e?.target?.value)){
        newState.error = "Email is invalid!"
      }
      else{
        newState.error =""
      }

      newState.value = e?.target?.value;

      return newState;
    });
  }

  
  const onEmailSend = async (e)=>{
    await sendEmail(true,"",email,true);
  }

  const onNextHandler = (e)=>{
    if(status=='open') navigate("/dashboard/deals");
    onNext();
  }

  

  return (
        <div className={styles["form"]}>
                <div>
                    <P>
                    Max 5 purchaser’s can be added. Please make sure to save email before clicking send email
                    </P>
                </div>
                {purchashers?.length<5 && <div>
                    <div className={styles["email"]}>
                        <div>
                            <Input onChange={onChange} value={email?.value} style={{borderTopRightRadius:0,borderBottomRightRadius:0}} type="text" placeholder="purchaser@gmail.com"/>
                            {/* <div><BsX/></div> */}
                        </div>
                        <div onClick={onEmailSend} style={{cursor:'pointer'}}>
                            Add
                        </div>
                    </div>
                    {email?.error && <div>{email?.error}</div>}
                </div>}
                <div>
                    {purchashers && purchashers.map((purchaser,idx)=>{
                        return <FilledBox idx={idx} syncData={syncDataDebounce} sendEmail={sendEmail} onPurchaserDelete={onPurchaserDelete} toggleReload={toggleReload} purchaser={purchaser}/>
                    })}
                </div>
                <div>
                    <SubmitBtn text={status!='open'?"Next":"Exit"} onClick={onNextHandler}/>
                </div>
            </div>
  )
}

export default ProjectInfo