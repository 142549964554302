import { BsArrowLeftCircle, BsDownload, BsLink45Deg } from "react-icons/bs";
import styles from "./index.module.css";

import React, { useEffect, useState } from 'react'
import H1 from "../../typography/H1";
import P from "../../typography/P";
import Label from "../../elements/Label";
import Input from "../../elements/Input";
import H5 from "../../typography/H5";
import DetailCard from "../../elements/DetailCard";
import { fetchAgentById } from "../../../utils/calls.utils";
import { useNavigate } from "react-router-dom";
import { redirectUsingFileName } from "../../../utils/download.utils";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllIncomesAsyncThunk } from "../../../store/slices/income/income.slice";

const PurchaserBox = ({purchaser})=>{
    const onDownload =async (name)=>{
        await redirectUsingFileName(name);
    }

    return <div className={styles["box"]}>
        <div className={styles["split"]}>
            {purchaser?.first_name && <div className={styles["input"]}>
                <Label>Purchaser 1</Label>
                <P style={{marginBottom:"0"}}>{purchaser?.first_name} {purchaser?.last_name}</P>
            </div>}
            {purchaser?.designation && <div className={styles["input"]}>
                <Label>Occupation</Label>
                <P style={{marginBottom:"0"}}>{purchaser?.designation}</P>
            </div>}
        </div>
        {purchaser?.company && <div className={styles["input"]}>
                <Label>Company</Label>
                <P style={{marginBottom:"0"}}>{purchaser?.company}</P>
        </div>}
        {purchaser?.email && <div className={styles["input"]}>
                <Label>Email</Label>
                <P style={{marginBottom:"0"}}>{purchaser?.email}</P>
        </div>}
        {purchaser?.address_line && <div className={styles["input"]}>
                <Label>Address</Label>
                <P style={{marginBottom:"0"}}>{purchaser?.address_line}</P>
        </div>}
        <div className={styles["split"]}>
            {purchaser?.phone && <div className={styles["input"]}>
                <Label>Phone No</Label>
                <P style={{marginBottom:"0"}}>{purchaser?.phone}</P>
            </div>}
            {purchaser?.dob && <div className={styles["input"]}>
                <Label>DOB</Label>
                <P style={{marginBottom:"0"}}>{purchaser?.dob?.split("T")[0]}</P>
            </div>}
        </div>
        <div>
                {purchaser?.utility_bill && purchaser?.driver_license && purchaser?.pr_or_pass && <Label>Documents</Label>}
                {purchaser?.driver_license && <div className={styles["documentBox"]}>
                    <div>
                        <H5>DRIVER LICENSE</H5>
                    </div>
                    <div>
                        <BsDownload onClick={e=>onDownload(purchaser?.driver_license)}/>
                    </div>
                </div>}
                {purchaser?.pr_or_pass && <div className={styles["documentBox"]}>
                    <div>
                        <H5>PR CARD OR PASSPORT</H5>
                    </div>
                    <div>
                        <BsDownload onClick={e=>onDownload(purchaser?.pr_or_pass)}/>
                    </div>
                </div>}
                {purchaser?.utility_bill && <div className={styles["documentBox"]}>
                    <div>
                        <H5>ANY UTILITY BILL</H5>
                    </div>
                    <div>
                        <BsDownload onClick={e=>onDownload(purchaser?.utility_bill)}/>
                    </div>
                </div>}
        </div>
    </div>;
}

const DepositBox = ({deposit, idx}) => {
    const onDownload =async ()=>{
        await redirectUsingFileName(deposit?.proof);
    }
    return <div className={styles["box"]}>
        <div className={styles["split"]}>
            <div className={styles["input"]}>
                <Label>Deposit {idx+1}</Label>
            </div>
        </div>
        <div className={styles["split"]}>
            {deposit?.amount && <div className={styles["input"]}>
                    <Label>Deposit Amount</Label>
                    <P style={{marginBottom:"0"}}>{deposit?.amount}</P>
            </div>}
            {deposit?.scheduled_date && <div className={styles["input"]}>
                <Label>Scheduled Date</Label>
                <P style={{marginBottom:"0"}}>{deposit?.scheduled_date?.split("T")[0]}</P>
            </div>}
        </div>
        <div>
                <div className={styles["documentBox"]}>
                    <div>
                        <H5>PROOF OF DEPOSIT</H5>
                    </div>
                    <div>
                        <BsDownload onClick={onDownload}/>
                    </div>
                </div>
        </div>
    </div>
}
const IncomeBox = ({income})=>{
    const onDownload =async ()=>{
        await redirectUsingFileName(income?.attachment);
    }
    return <div className={styles["box"]}>
        <div className={styles["split"]}>
            <div className={styles["input"]}>
                <Label>Label</Label>
                <P style={{marginBottom:"0"}}>{income?.label}</P>
            </div>
            <div className={styles["input"]}>
                <Label>Amount</Label>
                <P style={{marginBottom:"0"}}>{income?.amount}</P>
            </div>
        </div>
        <div className={styles["input"]}>
                <Label>Date</Label>
                <P style={{marginBottom:"0"}}>{income?.date?.split("T")[0]}</P>
        </div>
        {income?.attachment && <div>
                <div className={styles["documentBox"]}>
                    <div>
                        <H5>ATTACHMENT</H5>
                    </div>
                    <div>
                        <BsDownload onClick={onDownload}/>
                    </div>
                </div>
        </div>}
    </div>
}
const AgentBox = ({agent,idx,deal})=>{
    return <div className={styles["box"]}>
        <div className={styles["split"]}>
            <div className={styles["input"]}>
                <Label>Agent {idx}</Label>
            </div>
        </div>
        <div className={styles["split"]}>
            {agent?.agent?.name && <div className={styles["input"]}>
                <Label>Name</Label>
                <P style={{marginBottom:"0"}}>{agent?.agent?.name}</P>
            </div>}
            {agent?.commission && <div className={styles["input"]}>
                <Label>Commission</Label>
                <P style={{marginBottom:"0"}}>{agent?.isAbsolute?"$"+agent?.commission:"$"+(parseFloat(deal?.total_commission*(agent?.commission/100)).toFixed(2))}</P>
            </div>}
        </div>
        {agent?.type && <div className={styles["input"]}>
                <Label>Agent Type</Label>
                <P style={{marginBottom:"0"}}>{agent?.type}</P>
        </div>}
    </div>;
}
const DealDetails = () => {
    const navigate = useNavigate();
    const [deal,setDeal] = useState({});
    const [user,setUser] = useState({});
    const dispatch = useDispatch();
    const incomes = useSelector(state=>state.income);

    const getAgentDetailsPopulated =async (agentIds)=>{
        let newAgents = [];
        for(let i=0;i<agentIds?.length;i++){
            const res = await fetchAgentById(agentIds[i]?.agent);
            newAgents.push({...agentIds[i],agent:res?.data})
        }
        return newAgents;
    }

    useEffect(()=>{
        if(localStorage.getItem("user_")) setUser(JSON.parse(localStorage.getItem("user_")));
    },[]);

    useEffect(()=>{
        let deal = localStorage.getItem("deal_");

        if(!deal) return toast.error("Please go back to dashboard and come back!");

        deal = JSON.parse(deal);

        setDeal(deal);

        dispatch(fetchAllIncomesAsyncThunk({deal:deal?._id}));
    },[]);

    useEffect(()=>{
        if(!deal?.agent_details || deal?.agent_details?.length == 0) return;

        getAgentDetailsPopulated(deal?.agent_details).
        then(res=>{
            setDeal(state=>{
                const newDeal = JSON.parse(JSON.stringify(state));

                newDeal.agent_details = res;

                return newDeal;
            })
        })
        .catch(err=>{
            console.log(err);
            toast.error("Fetching agent details! Failed");
        })
    },[deal?.agent_details?.length])

  const onBack = ()=>{
    navigate("/dashboard/deals");
  }

  const onDownload = async (name)=>{
    if(name=="ds")
    await redirectUsingFileName(deal?.ds_signed);
    if(name=="1")
    await redirectUsingFileName(deal?.other_doc_1);
    if(name=="2")
    await redirectUsingFileName(deal?.other_doc_2);
    if(name=="3")
    await redirectUsingFileName(deal?.other_doc_3);
    if(name=="4")
    await redirectUsingFileName(deal?.other_doc_4);
    if(name=="5")
    await redirectUsingFileName(deal?.other_doc_5);
  }
  return (
    <div className={styles["container"]}>
        <div>
            <BsArrowLeftCircle style={{cursor:"pointer"}}onClick={onBack}/>
        </div>
        <div>
            <DetailCard style={{marginBottom:"calc(5 * var(--basey))"}}>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>Project Details</H1>
                    <P style={{marginBottom:"3rem",borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}></P>
                    <div className={styles["split"]}>
                        {deal?.project?.name&& <div className={styles["input"]}>
                            <Label>Project Name</Label>
                            <P style={{marginBottom:"0"}}>{deal?.project?.name}</P>
                        </div>}
                        {deal?.unit_no && <div className={styles["input"]}>
                            <Label>Preffered Unit No</Label>
                            <P style={{marginBottom:"0"}}>{deal?.unit_no}</P>
                        </div>}
                    </div>
                    {deal?.job_number && <div className={styles["input"]}>
                        <Label>Job Number</Label>
                        <P>{deal?.job_number}</P>
                    </div>}
                    {deal?.mortgage_notes && <div className={styles["input"]}>
                        <Label>Mortgage Notes</Label>
                        <P>{deal?.mortgage_notes}</P>
                    </div>}
                </div>
            </DetailCard>
            {deal?.purchasers?.length !== 0 && <DetailCard style={{marginTop:"0",marginBottom:"calc(5 * var(--basey))"}}>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>Purchaser Details</H1>
                    <P style={{marginBottom:"3rem",borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}></P>
                    {deal?.purchasers?.map(purchaser=><PurchaserBox purchaser={purchaser}/>)}
                </div>
            </DetailCard>}
            {deal?.sale_price  && <DetailCard style={{marginTop:"0",marginBottom:"calc(5 * var(--basey))"}}>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>Offer Financials</H1>
                    <P style={{marginBottom:"3rem",borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}></P>
                    <div className={styles["split"]}>
                        {deal?.sale_price && <div className={styles["input"]}>
                            <Label>Sale Price</Label>
                            <P style={{marginBottom:"0"}}>{deal?.sale_price}</P>
                        </div>}
                        {deal?.offer_acceptance_date && <div className={styles["input"]}>
                            <Label>Offer Acceptance</Label>
                            <P style={{marginBottom:"0"}}>{deal?.offer_acceptance_date?.split("T")[0]}</P>                            
                        </div>}
                    </div>
                    <div className={styles["split"]}>
                        {deal?.closing_date && <div className={styles["input"]}>
                            <Label>Closing Date</Label>
                            <P style={{marginBottom:"0"}}>{deal?.closing_date?.split("T")[0]}</P>
                        </div>}
                        {user?.role=="admin" && deal?.total_commission && <div className={styles["input"]}>
                            <Label>Commission</Label>
                            <P style={{marginBottom:"0"}}>{deal?.total_commission}</P>
                        </div>}
                    </div>
                    {user?.role=="admin" && deal?.init_commission!==0 && <div className={styles["input"]}>
                            <Label>Pre-Commission Date</Label>
                            <P style={{marginBottom:"0"}}>{deal?.init_commission_date?.split("T")[0]}</P>
                    </div>}
                    {user?.role=="admin" && deal?.init_commission!==0 && <div className={styles["input"]}>
                            <Label>Pre-Commission Amount</Label>
                            <P style={{marginBottom:"0"}}>{deal?.init_commission}</P>
                    </div>}
                    {
                        deal?.deposits?.map((deposit,idx)=>{
                            return <DepositBox deposit={deposit} idx={idx}/>
                        })
                    }
                </div>
            </DetailCard>}
            {deal?.agent_details && deal?.agent_details?.length != 0 && user?.role =="admin" && <DetailCard style={{marginTop:"0",marginBottom:"calc(5 * var(--basey))"}}>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>Agent Details</H1>
                    <P style={{marginBottom:"3rem",borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}></P>
               
                    {deal?.agent_details[0]?.agent?.name && <div className={styles["input"]}>
                        <Label>Main Agent Name</Label>
                        <P style={{marginBottom:"0"}}>{deal?.agent_details[0]?.agent?.name}</P>
                    </div>}
                    {
                        deal?.agent_details?.map((agent,idx)=>{if(idx==0) return <></>; else return <AgentBox agent={agent} idx={idx} deal={deal}/> })
                    }
                </div>
            </DetailCard>}
            {user?.role =="admin" &&<DetailCard style={{marginTop:"0",marginBottom:"calc(5 * var(--basey))"}}>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>Documents</H1>
                    <P style={{marginBottom:"3rem",borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}></P>
               
                    {deal?.ds_signed && <div>
                        <div className={styles["documentBox"]}>
                            <div>
                                <H5>Deal Secretery Document</H5>
                            </div>
                            <div>
                                <BsDownload onClick={()=>onDownload("ds")}/>
                            </div>
                        </div>
                    </div>}

                    {deal?.other_doc_label_1 && deal?.other_doc_1 && <div>
                        <div className={styles["documentBox"]}>
                            <div>
                                <H5>{deal?.other_doc_label_1}</H5>
                            </div>
                            <div>
                                <BsDownload onClick={()=>onDownload("1")}/>
                            </div>
                        </div>
                    </div>}
                    {deal?.other_doc_label_2 && deal?.other_doc_2 && <div>
                        <div className={styles["documentBox"]}>
                            <div>
                                <H5>{deal?.other_doc_label_2}</H5>
                            </div>
                            <div>
                                <BsDownload onClick={()=>onDownload("2")}/>
                            </div>
                        </div>
                    </div>}
                    {deal?.other_doc_label_3 && deal?.other_doc_3 && <div>
                        <div className={styles["documentBox"]}>
                            <div>
                                <H5>{deal?.other_doc_label_3}</H5>
                            </div>
                            <div>
                                <BsDownload onClick={()=>onDownload("3")}/>
                            </div>
                        </div>
                    </div>}
                    {deal?.other_doc_label_4 && deal?.other_doc_4 && <div>
                        <div className={styles["documentBox"]}>
                            <div>
                                <H5>{deal?.other_doc_label_4}</H5>
                            </div>
                            <div>
                                <BsDownload onClick={()=>onDownload("4")}/>
                            </div>
                        </div>
                    </div>}
                    {deal?.other_doc_label_5 && deal?.other_doc_5 && <div>
                        <div className={styles["documentBox"]}>
                            <div>
                                <H5>{deal?.other_doc_label_5}</H5>
                            </div>
                            <div>
                                <BsDownload onClick={()=>onDownload("5")}/>
                            </div>
                        </div>
                    </div>}
                    
                </div>
            </DetailCard>}
            {user?.role =="admin" &&<DetailCard style={{marginTop:"0",marginBottom:"calc(5 * var(--basey))"}}>
                <div className={styles["wrapper"]}>
                    <H1 style={{marginBottom:"1.1rem"}}>Incomes</H1>
                    <P style={{marginBottom:"3rem",borderBottom:"1px solid var(--color_dark_1)",paddingBottom:"1.1rem"}}></P>
                    {incomes?.incomes?.map(income=>{
                        return <IncomeBox income={income}/>
                    })}    
                </div>
            </DetailCard>}
            <div className={styles["cta"]} onClick={onBack}>
                <H5>Back To Deals</H5>
            </div>
        </div>
    </div>
  )
}

export default DealDetails